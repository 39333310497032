import logger from '~/services/logger';
import { getCellAssetSrc } from '~/services/layout/layout.ts';
import { twMerge } from 'tailwind-merge';
import { type FeedEntry } from '~/services/layout/index.server.ts';
import {
  useFavoriteAction,
  type UseFavoriteActionReturnType,
} from '~/hooks/use-favorite-action.ts';

export function ActionIcon({
  entry,
  cellConfig,
  isPositionedAbsolute = true,
}: {
  entry: FeedEntry;
  cellConfig: any;
  isPositionedAbsolute?: boolean;
}): JSX.Element {
  const {
    isFavorite,
    favoriteAction,
    shouldRenderFavoriteAction,
  }: UseFavoriteActionReturnType = useFavoriteAction({
    entry,
  });

  if (!shouldRenderFavoriteAction) return <></>;

  const selectedActionIcon: string | undefined =
    getCellAssetSrc(cellConfig, 'selected_action_icon', 'action_switch') ||
    getCellAssetSrc(
      cellConfig,
      'favorites_action_icon',
      'favorites_action_switch',
      'styles'
    );

  const unselectedActionIcon: string | undefined =
    getCellAssetSrc(cellConfig, 'unselected_action_icon', 'action_switch') ||
    getCellAssetSrc(
      cellConfig,
      'unfavorites_action_icon',
      'favorites_action_switch',
      'styles'
    );

  if (!selectedActionIcon || !unselectedActionIcon) return <></>;

  try {
    return (
      <button
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          favoriteAction();
        }}
        className={twMerge(
          'z-40 mb-cell-action-icon-b ml-cell-action-icon-l mr-cell-action-icon-r mt-cell-action-icon-t opacity-100 action-icon-pos',
          isPositionedAbsolute && 'absolute'
        )}
        data-testid={`action-icon-${entry.id}`}
        key={entry.id}
      >
        <img
          alt="action icon alt"
          className={`h-cell-action-icon w-cell-action-icon`}
          src={isFavorite ? selectedActionIcon : unselectedActionIcon}
        />
      </button>
    );
  } catch (error: any) {
    logger.info(`ActionIcon: ${error.message}`);
    return <></>;
  }
}
