import get from 'lodash.get';

/**
 * Get text data for Cell text labels using getTextLabelFromPayload function
 * @param entryPayload
 * @param cellConfig
 * @returns
 */
export function cellTextLabels(
  entryPayload: any,
  cellConfig: any
): { [key: string]: string | undefined } {
  const labels: string[][] = [
    ['title', 'title'],
    ['title_text', 'title_text'],
    ['text_label1', 'text_label_1'],
    ['text_label2', 'text_label_2'],
    ['text_label3', 'text_label_3'],
    ['text_label4', 'text_label_4'],

    ['top_text_label1', 'top_text_label_1'],
    ['top_text_label2', 'top_text_label_2'],
    ['top_text_label3', 'top_text_label_3'],

    ['center_text_label1', 'center_text_label_1'],
    ['center_text_label2', 'center_text_label_2'],
    ['center_text_label3', 'center_text_label_3'],

    ['bottom_text_label1', 'bottom_text_label_1'],
    ['bottom_text_label2', 'bottom_text_label_2'],
    ['bottom_text_label3', 'bottom_text_label_3'],
    ['text_button1', 'text_button_1'],
    ['button_text', 'button_text'],
  ];

  const textLabels: { [key: string]: string | undefined } = labels.reduce(
    (acc: any, [varName, fieldName]: any) => {
      acc[varName] = getTextLabelFromPayload(
        fieldName,
        entryPayload,
        cellConfig
      );
      return acc;
    },
    {}
  );

  return textLabels;
}

/**
 * Get cell text label from entryPayload based on
 * custom_data_key || data_key, where <KEY>_switch is truthy OR undefined.
 * @param textLabelKey
 * @param entryPayload
 * @param cellConfig
 * @returns string value from the feed entry payload OR undefined
 */
function getTextLabelFromPayload(
  textLabelKey: string,
  entryPayload: any,
  cellConfig: any
): string | undefined {
  const path: string | undefined = getTextLabelPayloadPath(
    cellConfig?.configuration?.styles,
    textLabelKey
  );

  const switchMode: boolean | undefined =
    cellConfig?.configuration?.styles?.[`${textLabelKey}_switch`];

  if (typeof path === 'undefined') return undefined;

  const payloadRes = get(entryPayload, path!);

  if (textLabelKey === 'button_text' && typeof payloadRes === 'undefined')
    return path;

  if (typeof switchMode === 'undefined') return get(entryPayload, path!);

  const res = switchMode ? get(entryPayload, path!) : undefined;

  return res;
}

function getTextLabelPayloadPath(
  cellConfigRoot: any,
  key: string
): string | undefined {
  const isOther =
    cellConfigRoot?.[`${key}_key`] === 'other' ||
    cellConfigRoot?.[`${key}_data_key`] === 'other';

  if (isOther) return cellConfigRoot?.[`${key}_custom_data_key`];

  return cellConfigRoot?.[`${key}_data_key`] || cellConfigRoot?.[`${key}_key`];
}
