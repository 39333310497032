import { useEffect, useState } from 'react';
import logger from '~/services/logger';
import { useIsMobile } from './use-responsive-breakpoint.ts';

/**
 * @returns {number} Sum of left and right padding of the screen + FIXED_PADDING_ADDITION || 0
 */
export function useScreenPadding(): number {
  const [screenPadding, setScreenPadding] = useState<number>(0);

  const isMobile: boolean = useIsMobile();

  useEffect(() => {
    const screenPadding: number = getScreenPadding(isMobile);

    setScreenPadding(screenPadding);
  }, [isMobile]);

  return screenPadding || 0;
}

/**
 * Get the sum of left and right padding of the screen.
 * Calculates screen padding and mobile screen padding.
 * If the screen padding is 0
 * @returns {number} Sum of pl-screen-l pr-screen-r OR pl-mobile-screen-l pr-mobile-screen-r || 0
 */
function getScreenPadding(isMobile: boolean): number {
  try {
    const div = document.createElement('div');
    div.className = isMobile
      ? 'pl-mobile-screen-l pr-mobile-screen-r'
      : 'pl-screen-l pr-screen-r';
    div.style.display = 'none';

    document.body.appendChild(div);

    const computedPaddingLeft = getComputedStyle(div).paddingLeft;
    const computedPaddingRight = getComputedStyle(div).paddingRight;
    const sum =
      parseFloat(computedPaddingLeft) + parseFloat(computedPaddingRight);

    document.body.removeChild(div);

    return sum;
  } catch (error: any) {
    logger.info(`getScreenPadding: ${error.message}`);
    return 0;
  }
}
