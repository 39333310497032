const manifest = {
  api: {},
  plugin_id: 3742,
  identifier: 'empty_group_v2',
  manifest_version: '1.0.2',
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Empty Group',
  description: 'Empty Group',
  type: 0,
  screen: false,
  react_native: true,
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  min_zapp_sdk: '0.0.1',
  general: {
    fields: [
      {
        key: 'empty_group_image',
        label: 'Empty Group Image',
        label_tooltip: 'Image to display when there is no content.',
        type: 'uploader',
      },
      {
        key: 'entry_image_key_name',
        label: 'Entry Asset Key Name',
        label_tooltip: 'Defines the key used for the image provided above.',
        initial_value: 'image_base',
        type: 'text_input',
      },
    ],
  },
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell Styles',
        folded: false,
        fields: [
          {
            key: 'mobile_cell_plugin_configuration_id',
            label: 'Mobile cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'tablet_cell_plugin_configuration_id',
            label: 'Tablet cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'desktop_cell_plugin_configuration_id',
            label: 'Desktop cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'large_desktop_cell_plugin_configuration_id',
            label: 'Large Desktop cell style',
            type: 'cell_style_selector',
          },
        ],
      },
      {
        group: true,
        label: 'Empty Group Styles',
        folded: false,
        fields: [
          {
            key: 'component_mobile_background_color',
            label: 'Component Mobile Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_tablet_background_color',
            label: 'Component Tablet Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_desktop_background_color',
            label: 'Component Desktop Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_large_desktop_background_color',
            label: 'Component Large Desktop Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_mobile_margin_top',
            label: 'Component Mobile Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_margin_top',
            label: 'Component Tablet Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_margin_top',
            label: 'Component Desktop Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_margin_top',
            label: 'Component Large Desktop Margin Top',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_margin_bottom',
            label: 'Component Mobile Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_margin_bottom',
            label: 'Component Tablet Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_margin_bottom',
            label: 'Component Desktop Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_margin_bottom',
            label: 'Component Large Desktop Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_padding_top',
            label: 'Component Mobile Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_top',
            label: 'Component Tablet Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_top',
            label: 'Component Desktop Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_top',
            label: 'Component Large Desktop Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_right',
            label: 'Component Mobile Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_right',
            label: 'Component Tablet Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_right',
            label: 'Component Desktop Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_right',
            label: 'Component Large Desktop Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_bottom',
            label: 'Component Mobile Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_bottom',
            label: 'Component Tablet Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_bottom',
            label: 'Component Desktop Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_bottom',
            label: 'Component Large Desktop Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_padding_left',
            label: 'Component Mobile Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_left',
            label: 'Component Tablet Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_left',
            label: 'Component Desktop Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_left',
            label: 'Component Large Desktop Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'use_maximum_component_width',
            label: 'Use Maximum Component Width',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'component_mobile_maximum_width',
            label: 'Component Mobile Maximum Width',
            initial_value: 275,
            type: 'number_input',
          },
          {
            key: 'component_tablet_maximum_width',
            label: 'Component Tablet Maximum Width',
            initial_value: 340,
            type: 'number_input',
          },
          {
            key: 'component_desktop_maximum_width',
            label: 'Component Desktop Maximum Width',
            initial_value: 456,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_maximum_width',
            label: 'Component Large Desktop Maximum Width',
            initial_value: 456,
            type: 'number_input',
          },
        ],
      },
    ],
  },
  localizations: {
    fields: [
      {
        type: 'text_input',
        label: 'Empty Group Title',
        key: 'empty_group_title',
        initial_value: 'No Content Available',
      },
      {
        type: 'text_input',
        label: 'Empty Group Summary',
        key: 'empty_group_summary',
        initial_value:
          'There is nothing to show here for now. Please try again later.',
      },
    ],
  },
  characteristics: {
    group: false,
    appearance: ['group'],
    skip_cell_styles_selector: false,
    disable_default_cell_style: true,
  },
} as const;

export default manifest;
