import { twMerge } from 'tailwind-merge';

export type TextComponent =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div';

const textComponentMapping: Record<TextComponent, keyof JSX.IntrinsicElements> =
  {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
    div: 'div',
  };

export default function Label({
  text,
  className,
  component = 'p',
  iconSrc,
}: {
  text?: string;
  className?: string;
  component?: TextComponent;
  iconSrc?: string;
}) {
  if (!text) return null;

  // For some reason there is no way to set text alignment using a css var
  const style: any = { textAlign: `var(--text-label-alignment)` };

  const TextComponent = textComponentMapping[component];

  return (
    <div className={twMerge(className)} style={style}>
      <div
        className={`mb-label-b ml-label-l mr-label-r mt-label-t inline-block whitespace-pre-wrap rounded-label bg-label pb-label-b pl-label-l pr-label-r pt-label-t font-label-font-family text-label-font-size-sm leading-label-line-height text-label-color text-transform lg:text-label-font-size ${
          iconSrc ? 'flex items-center' : ''
        }`}
      >
        {iconSrc ? (
          <img src={iconSrc} alt={text} className="mr-2 h-6 w-6" />
        ) : null}
        <TextComponent className="line-clamp-label-line-clamp">
          {text}
        </TextComponent>
      </div>
    </div>
  );
}
