import get from 'lodash.get';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import type { Feed, UIComponent } from '~/services/layout/index.server.ts';
import { getComponentIcons } from '~/utils/get-component-icons.ts';
import { ChevronIcon } from '~/components/cell-components/chevron-icon.tsx';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import manifest from '../config/manifest';

export const maxHeroCarouselLimit: number = 6;
const defaultHeroCarouselAutoplaySpeed: number = 6;
export function HeroCarousel({
  feed,
  feedEntryLength,
  currentEntryIndex,
  showNextEntry,
  showPrevEntry,
  showEntry,
  uiComponent,
}: {
  feed: Feed | undefined;
  feedEntryLength: number;
  currentEntryIndex: number;
  showNextEntry: () => void;
  showPrevEntry: () => void;
  showEntry: (index: number) => void;
  uiComponent: UIComponent;
}) {
  const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

  const { isCarouselEnabled, carouselLeftButtonIcon, carouselRightButtonIcon } =
    getHeroCarouselSettings(
      uiComponent,
      config.styles?.hero_carousel_switch as boolean,
      config.styles?.hero_carousel_autoplay_switch as boolean,
      config.styles?.hero_carousel_autoplay_speed as number
      // config.styles.hero_carousel_autoplay_pause_on_hover as boolean
    );

  if (!feed || feedEntryLength <= 1 || !isCarouselEnabled) return <></>;

  return (
    <div
      className={
        'absolute bottom-5 right-5 z-10 flex items-center justify-start gap-1 lg:gap-5'
      }
    >
      <button onClick={showPrevEntry} className="h-10 w-10">
        {carouselLeftButtonIcon ? (
          <img
            alt={`carousel left button icon`}
            className="h-10 w-10"
            src={carouselLeftButtonIcon}
            key={`hero-carousel-left-btn-icon-${uiComponent.id}`}
          />
        ) : (
          <ChevronIcon className="h-10 w-10 text-white" direction={'left'} />
        )}
      </button>

      <HeroCarouselEntriesIndicator
        feed={feed}
        feedEntryLength={feedEntryLength}
        currentEntryIndex={currentEntryIndex}
        onIndicatorClick={showEntry}
      />

      <button onClick={showNextEntry} className="h-10 w-10">
        {carouselRightButtonIcon ? (
          <img
            alt={`carousel right button icon`}
            className="h-10 w-10"
            src={carouselRightButtonIcon}
            key={`hero-carousel-right-btn-icon-${uiComponent.id}`}
          />
        ) : (
          <ChevronIcon className="h-10 w-10 text-white" direction={'right'} />
        )}
      </button>
    </div>
  );
}

function HeroCarouselEntriesIndicator({
  feed,
  feedEntryLength,
  currentEntryIndex,
  onIndicatorClick,
}: {
  feed: Feed;
  feedEntryLength: number;
  currentEntryIndex: number;
  onIndicatorClick: (index: number) => void;
}) {
  return (
    <div className="flex items-center justify-start gap-2 lg:gap-4">
      {React.Children.toArray(
        feed.entry.slice(0, feedEntryLength).map((_: any, index: number) => {
          return (
            <div
              className={twMerge(
                'h-1 w-1 cursor-pointer rounded-full bg-hero-carousel-indicator transition-colors hover:bg-hero-carousel-indicator-active lg:h-3 lg:w-3',
                currentEntryIndex === index
                  ? 'bg-hero-carousel-indicator-active'
                  : 'bg-hero-carousel-indicator'
              )}
              onClick={() => onIndicatorClick(index)}
            />
          );
        })
      )}
    </div>
  );
}

export function getHeroCarouselSettings(
  uiComponent: UIComponent,
  heroCarouselSwitch?: boolean,
  heroCarouselAutoplaySwitch?: boolean,
  heroCarouselAutoplaySpeed?: number,
  heroCarouselAutoplayPauseOnHover?: boolean
): {
  isCarouselEnabled: boolean;
  isCarouselAutoplay?: boolean;
  carouselAutoplaySpeed: number;
  carouselAutoplayPauseOnHover?: boolean;
  carouselRightButtonIcon?: string;
  carouselLeftButtonIcon?: string;
} {
  const isCarouselEnabled: boolean =
    typeof heroCarouselSwitch === 'boolean' && heroCarouselSwitch === false
      ? false
      : true;

  if (!isCarouselEnabled)
    return {
      isCarouselEnabled: false,
      carouselAutoplaySpeed: defaultHeroCarouselAutoplaySpeed,
    };

  // TODO: remove uiComponent after HorizontalList and HorizontalListButton are refactored
  const { leftBtnIcon, rightBtnIcon } = getComponentIcons(uiComponent);

  return {
    isCarouselEnabled: true,
    isCarouselAutoplay: heroCarouselAutoplaySwitch,
    carouselAutoplaySpeed:
      heroCarouselAutoplaySpeed ?? defaultHeroCarouselAutoplaySpeed,
    carouselAutoplayPauseOnHover: heroCarouselAutoplayPauseOnHover || false,
    carouselLeftButtonIcon: leftBtnIcon,
    carouselRightButtonIcon: rightBtnIcon,
  };
}

export function getHeroCarouselTransitionDuration(component: any) {
  const transitionDuration: string | undefined = get(
    component,
    'styles.hero_carousel_transition_duration'
  );

  const isDefualt: boolean =
    !transitionDuration ||
    (typeof transitionDuration !== 'number' &&
      typeof transitionDuration !== 'string');

  if (isDefualt) return '700ms';

  if (Number(transitionDuration) >= 100) return `${transitionDuration}ms`;

  return `${transitionDuration}s`;
}

export function getHeroCarouselUtils({
  feed,
  feedEntryLength,
  currentEntryIndex,
  setCurrentEntryIndex,
  setShowEntries,
}: {
  feed: Feed | undefined;
  feedEntryLength: number;
  currentEntryIndex: number;
  setCurrentEntryIndex: (index: number) => void;
  setShowEntries: (showEntries: boolean[]) => void;
}): {
  showEntry: (index: number) => void;
  showNextEntry: () => void;
  showPrevEntry: () => void;
} {
  return {
    showEntry,
    showNextEntry,
    showPrevEntry,
  };

  function showNextEntry(): void {
    if (!feed) return;

    const nextIndex: number = (currentEntryIndex + 1) % feedEntryLength;

    showEntry(nextIndex);
  }

  function showPrevEntry(): void {
    if (!feed) return;

    const prevIndex: number =
      (currentEntryIndex - 1 + feedEntryLength) % feedEntryLength;

    showEntry(prevIndex);
  }

  function showEntry(index: number): void {
    const initialShowEntries: boolean[] = new Array(feedEntryLength).fill(
      false
    );

    const isEntryIndexValid: boolean = !!feed?.entry?.[index];

    if (isEntryIndexValid) {
      initialShowEntries[index] = true;
    }

    setCurrentEntryIndex(index);
    setShowEntries(initialShowEntries);
  }
}
