import { getCellAssetSrc } from '~/services/layout/layout.ts';
import Label from '../label.tsx';
import { MaybeLink } from '../maybe-link.tsx';
import { cellTextLabels } from '~/services/layout/text-labels.ts';

import type { CellProps } from './index.tsx';
import { twMerge } from 'tailwind-merge';
import { useScreenPadding } from '~/hooks/use-screen-padding.ts';

function CellInfo({
  cellId,
  entry,
  cellConfig,
  linkTo,
  selectable,
  analyticsData,
}: CellProps) {
  const { title_text, button_text } = cellTextLabels(entry, cellConfig);

  const badgeAsset: string | undefined = getCellAssetSrc(
    cellConfig,
    'badge_asset',
    'badge_asset_switch'
  );

  const styles = cellConfig?.configuration?.styles;
  const titleTextSwitch: boolean | undefined = styles?.title_text_switch;
  const horizontalPosition = styles?.button_element_horizontal_position;

  const buttonIcon: string | undefined = getCellAssetSrc(
    cellConfig,
    'button_icon_asset',
    'button_icon_switch',
    'styles'
  );

  const isLinkable: boolean = !!selectable && !!linkTo;

  const screenPadding: number = useScreenPadding();

  return (
    <div className={`cell-${cellId} flex w-cell shrink-0`}>
      <div
        className={twMerge(
          'flex w-full shrink-0 items-center bg-transparent',
          horizontalPosition === 'left' ? 'flex-start' : 'justify-between'
        )}
      >
        {badgeAsset && (
          <img
            alt="badge"
            className="mb-cell-info-badge-b ml-cell-info-badge-l mr-cell-info-badge-r mt-cell-info-badge-t aspect-cell-info-badge w-cell-info-badge rounded-cell-info-badge object-contain"
            src={badgeAsset}
          />
        )}
        {titleTextSwitch && !!title_text && (
          <MaybeLink
            to={linkTo}
            enabled={selectable}
            analyticsdata={analyticsData}
          >
            <Label
              className={`cell-${cellId}-text`}
              component={styles?.title_text_typography}
              text={title_text}
            />
          </MaybeLink>
        )}
        {button_text && isLinkable && (
          <MaybeLink
            to={linkTo}
            enabled={selectable}
            analyticsdata={analyticsData}
          >
            <div
              className="flex items-center"
              style={{ paddingRight: `${screenPadding}px` }}
            >
              <Label
                className={`cell-${cellId}-button-text`}
                component={styles?.button_text_typography}
                text={button_text}
              />
              {buttonIcon && (
                <img
                  alt="button icon"
                  className={`cell-${cellId}-button-icon h-cell-button-icon w-cell-button-icon`}
                  src={buttonIcon}
                />
              )}
            </div>
          </MaybeLink>
        )}
      </div>
    </div>
  );
}

export default CellInfo;
