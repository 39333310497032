import { useEffect } from 'react';
import type {
  Feed,
  FeedEntry,
  UIComponent,
} from '~/services/layout/index.server.ts';
import {
  filterSkeletonEntries,
  generateSkeletonEntries,
  getLimit,
} from '~/services/layout/layout';

export function useFeedEntries({
  feedEntries,
  setFeedEntries,
  isLoading,
  isClient,
  uiComponent,
  feed,
}: {
  feedEntries: FeedEntry[];
  setFeedEntries: any;
  isLoading?: boolean | undefined;
  isClient?: boolean | undefined;
  uiComponent: UIComponent;
  feed: Feed | undefined;
}) {
  const limit: number | undefined = getLimit(uiComponent.rules?.item_limit);

  const entries: FeedEntry[] | [] =
    isLoading && isClient
      ? generateSkeletonEntries()
      : feed?.entry?.slice(0, limit) || [];

  useEffect(() => {
    if (!isLoading || !isClient) {
      setFeedEntries(entries);
      return;
    }

    setFeedEntries((prevFeedEntries: any[]) => {
      return filterSkeletonEntries(prevFeedEntries);
    });
  }, [isLoading]);

  return feedEntries?.length > entries?.length ? feedEntries : entries;
}

export function useFeedEntriesV1({
  initialFeedEntries,
  setFeedEntries,
}: {
  initialFeedEntries: FeedEntry[] | [];
  setFeedEntries: any;
}) {
  useEffect(() => {
    setFeedEntries(initialFeedEntries);
  }, []);
}
