import logger from '~/services/logger';
import { twMerge } from 'tailwind-merge';

export default function DebugComponent({ uiComponent, error, feed }: any) {
  if (process.env.NODE_ENV === 'production') {
    return <></>;
  }

  const label = error ? 'error' : 'info';

  const isAddHeight =
    error !== undefined ||
    feed?.entry?.length === 0 ||
    typeof feed === 'undefined';

  return (
    <div className={twMerge('relative', isAddHeight && 'h-6')}>
      <button
        className="absolute z-50 bg-red-500 px-2"
        onClick={(e) => {
          e.preventDefault();
          if (error) {
            logger.info(error);
          }
          logger.info(`${uiComponent} ${feed}`);
        }}
      >
        {label}
      </button>
    </div>
  );
}
