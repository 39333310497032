import { useRouteLoaderData, useMatches } from '@remix-run/react';
import type { loader } from '~/routes/_index.tsx';
import type { FeedEntry, UIComponent } from '~/services/layout/index.server.ts';
import { useMemo } from 'react';
import { type EventName, getTapCellAnalyticsData } from '~/utils/analytics';

export const useAnalyticsData = () => {
  const routeMatches = useMatches();
  const lastRouteId = routeMatches[routeMatches.length - 1]?.id;

  const data = useRouteLoaderData<typeof loader>(lastRouteId);
  return data?.analyticsTrackData || {};
};

export type TapCellAnalyticsData = {
  eventName: EventName;
  trackData: any;
};

export const useTapCellAnalyticsData = ({
  uiComponent,
  entry,
}: {
  uiComponent: UIComponent;
  entry: FeedEntry | undefined;
}): TapCellAnalyticsData => {
  const analyticsTrackData = useAnalyticsData();

  const tapCellAnalyticsData = useMemo(
    () =>
      getTapCellAnalyticsData(
        'tap_cell',
        analyticsTrackData,
        uiComponent,
        entry
      ),
    [analyticsTrackData, uiComponent, entry]
  );

  return tapCellAnalyticsData;
};
