import { twMerge } from 'tailwind-merge';
import type { FeedEntry } from '~/services/layout/index.server.ts';
import { getCSSNumber } from '~/services/layout/layout';

export function getWidth({
  ref,
  gutter,
}: {
  ref: any;
  gutter: number | undefined;
}): number {
  if (!ref?.current) return 0;
  if (!gutter) gutter = 0;
  try {
    const width = parseInt(getComputedStyle(ref.current)?.width) + gutter;
    return width || 0;
  } catch (error) {
    return 0;
  }
}

export function getPayloadImageHeight({ ref }: any): string {
  if (!ref?.current) return 'auto';
  try {
    const maxHeight = Array.from(
      ref.current.querySelectorAll('[data-payload-image]')
    )
      .map((img: any) => img.getBoundingClientRect().height)
      .reduce(
        (maxHeight: number, currentHeight: number) =>
          Math.max(maxHeight, currentHeight),
        0
      );

    return maxHeight !== 0 ? `${maxHeight}px` : 'auto';
  } catch (error) {
    return 'auto';
  }
}

export function getCellsScrolled({
  ref,
  cellRef,
  countFrom = 'start',
  gutter,
}: {
  ref: any;
  cellRef: any;
  countFrom?: 'start' | 'end';
  gutter: number | undefined;
}): number {
  if (!ref.current) return 0;

  const cellWidth: number = getWidth({ ref: cellRef, gutter });
  const visibleCells: number = getVisibleCells({
    ref,
    cellRef,
    gutter: gutter || 0,
  });
  const scrollLeft: number = getScrollLeft({ ref });
  const scrolled: number = Math.floor(scrollLeft / cellWidth);

  if (scrolled === 0 || scrolled === Infinity) return 0;

  return countFrom === 'start' ? scrolled : scrolled + visibleCells;
}

export function getToggleButtonsState({
  ref,
  circularCondition,
  nextFeedUrl,
}: {
  ref: any;
  circularCondition?: boolean;
  nextFeedUrl?: string;
}): {
  right: boolean;
  left: boolean;
} {
  if (!ref.current) return { right: false, left: false };

  if (circularCondition) return { right: true, left: true };

  const scrollLeft: number = getScrollLeft({ ref });
  const clientWidth: number = getClientWidth({ ref });
  const totalScrollWidth: number = getTotalScrollWidth({ ref });

  const right: boolean = !(scrollLeft >= totalScrollWidth - clientWidth);

  return {
    right: nextFeedUrl ? true : right,
    left: scrollLeft > 0,
  };
}

export function getScrollLeft({ ref }: { ref: any }): number {
  if (!ref.current) return 0;
  return ref.current?.scrollLeft;
}

export function getTotalScrollWidth({ ref }: { ref: any }): number {
  if (!ref.current) return 0;
  return ref.current?.scrollWidth;
}

export function getTotalCells({
  entries,
  isClient,
}: {
  entries: FeedEntry[] | undefined;
  isClient: boolean | undefined;
}): number {
  let entriesLength = entries?.length || 0;

  if (
    entries?.every((obj: Object) => Object.keys(obj)?.length === 0) &&
    isClient
  ) {
    return 0;
  }

  return entriesLength;
}

export function getClientWidth({ ref }: { ref: any }): number {
  const clientWidth = ref.current?.clientWidth;
  if (!clientWidth) return 0;
  return clientWidth;
}

export function getVisibleCells({
  ref,
  cellRef,
  gutter,
}: {
  ref: any;
  cellRef: any;
  gutter: number;
}): number {
  const clientWidth: number = getClientWidth({
    ref,
  });

  const cellWidth: number = getWidth({
    ref: cellRef,
    gutter,
  });

  const visibleCells = Math.floor(clientWidth / cellWidth);

  if (isNaN(visibleCells)) return 0;

  if (visibleCells < 0) return 0;

  return Math.floor(clientWidth / cellWidth);
}

export function getHorizontalListScrollSnapClasses(
  styles: any,
  cellScalingSwitch: boolean
): string {
  const mobileOneCell =
    styles?.mobile_cells_per_view === 1 && cellScalingSwitch;
  const tabletOneCell =
    styles?.tablet_cells_per_view === 1 && cellScalingSwitch;
  const desktopOneCell =
    styles?.desktop_cells_per_view === 1 && cellScalingSwitch;
  const largeDesktopOneCell =
    styles?.large_desktop_cells_per_view === 1 && cellScalingSwitch;

  return twMerge(
    mobileOneCell && 'mobile:max-tablet:snap-center',
    tabletOneCell && 'tablet:max-desktop:snap-center',
    desktopOneCell && 'desktop:max-large-desktop:snap-center',
    largeDesktopOneCell && 'large-desktop:snap-center'
  );
}

export function getIsAllCellsInView({
  styles,
  entriesCount,
}: {
  styles: any;
  entriesCount: number;
}) {
  const mobileCellsPerView = styles?.mobile_cells_per_view;
  const tabletCellsPerView = styles?.tablet_cells_per_view;
  const desktopCellsPerView = styles?.desktop_cells_per_view;
  const largeDesktopCellsPerView = styles?.large_desktop_cells_per_view;

  return {
    mobile: mobileCellsPerView >= entriesCount,
    tablet: tabletCellsPerView >= entriesCount,
    desktop: desktopCellsPerView >= entriesCount,
    largeDesktop: largeDesktopCellsPerView >= entriesCount,
  };
}

export function getHorizontalListButtonsVisibility({
  ref,
  cellRef,
  feedEntriesLength,
  circularCondition,
  isNextFeedLoading,
  gutter,
}: {
  ref: any;
  cellRef: any;
  feedEntriesLength: number;
  circularCondition: boolean;
  isNextFeedLoading: boolean;
  gutter: number;
}): {
  showLeftButton: boolean;
  showRightButton: boolean;
} {
  const element = ref?.current;

  if (!element) return { showLeftButton: false, showRightButton: false };

  const cellsScrolled = getCellsScrolled({
    ref,
    cellRef,
    countFrom: 'end',
    gutter,
  });

  const hideRightButton =
    feedEntriesLength === cellsScrolled &&
    !isNextFeedLoading &&
    !circularCondition;

  return {
    showLeftButton: element.scrollLeft >= 1 && !circularCondition,
    showRightButton:
      Math.ceil(element.scrollLeft) <
        element.scrollWidth - element.offsetWidth && !hideRightButton,
  };
}

export function getHorizontalListButtonPosition({
  position,
  cellRef,
  ref,
}: {
  position: string;
  cellRef: any;
  ref: any;
}): string {
  if (position === 'image_center')
    return getPayloadImageHeight({ ref: cellRef });

  return (
    ref.current
      ?.querySelector('[data-horizontal-list-container]')
      ?.getBoundingClientRect()?.height || 'auto'
  );
}

export function getIsLastCell({
  ref,
  cellRef,
  feedEntriesLength,
  gutter,
}: {
  ref: any;
  cellRef: any;
  feedEntriesLength: number;
  gutter: number;
}) {
  const currentLeftsideCellIndex =
    getCellsScrolled({ ref, cellRef, countFrom: 'end', gutter }) + 1;

  const componentPaddingRight = getCSSNumber(ref.current, 'padding-right');

  const isRightMost =
    parseInt(ref.current?.scrollLeft) >=
    ref.current?.scrollWidth - window.innerWidth - componentPaddingRight;

  const isLastCell =
    currentLeftsideCellIndex >= feedEntriesLength && isRightMost;

  return isLastCell;
}

export function getIsFirstCell({
  ref,
  cellRef,
  gutter,
}: {
  ref: any;
  cellRef: any;
  gutter: number;
}) {
  const currentLeftsideCellIndex =
    getCellsScrolled({ ref, cellRef, countFrom: 'start', gutter }) + 1;

  const componentPaddingLeft = getCSSNumber(ref.current, 'padding-left');

  const isFirstCell =
    currentLeftsideCellIndex === 1 &&
    ref.current?.scrollLeft - componentPaddingLeft <= 0;

  return isFirstCell;
}
