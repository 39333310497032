const manifest = {
  plugin_id: 1031,
  identifier: 'cell_1_mobile',
  manifest_version: '1.0.0-alpha.1',
  name: 'Cell 1 Mobile',
  description: 'Mobile power cell with image above text',
  type: 'cell_builder',
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell',
        folded: false,
        fields: [
          {
            key: 'cell_background_color',
            label: 'Background color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_background_color_focused',
            label: 'Focused background color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_corner_radius',
            label: 'Corner radius',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_top',
            label: 'Padding top',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_right',
            label: 'Padding right',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_bottom',
            label: 'Padding bottom',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_padding_left',
            label: 'Padding left',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_color',
            label: 'Shadow color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'cell_shadow_opacity',
            label: 'Shadow opacity',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_offset_width',
            label: 'Shadow offset width',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_offset_height',
            label: 'Shadow offset height',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'cell_shadow_radius',
            label: 'Shadow radius',
            initial_value: 0,
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Runtime label',
        folded: false,
        fields: [
          {
            key: 'runtime_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'runtime_font_color',
            label: 'Font color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 1)',
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'runtime_font_family_font_selector',
            label: 'Font Family',
            initial_value: 'SFProText-Medium',
            placeholder: 'select font',
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'runtime_font_size',
            label: 'Font size',
            rules: 'conditional',
            initial_value: 11,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_line_height',
            label: 'Line height',
            rules: 'conditional',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_letter_spacing',
            label: 'Letter spacing',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_top',
            label: 'Padding top',
            rules: 'conditional',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_right',
            label: 'Padding right',
            rules: 'conditional',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_bottom',
            label: 'Padding bottom',
            rules: 'conditional',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_padding_left',
            label: 'Padding left',
            rules: 'conditional',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_background_color',
            label: 'Background color',
            rules: 'conditional',
            initial_value: 'rgba(0, 0, 0, 0.9)',
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'runtime_corner_radius',
            label: 'Corner radius',
            rules: 'conditional',
            initial_value: 2,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 10,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'runtime_margin_bottom',
            label: 'Margin Bottom',
            rules: 'conditional',
            initial_value: 10,
            conditional_fields: [
              {
                key: 'styles/runtime_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Text label 1',
        folded: false,
        fields: [
          {
            key: 'text_label_1_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'text_label_1_data_key',
            label: 'Data key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            rules: 'conditional',
            options: [
              {
                text: 'summary',
                value: 'summary',
              },
              {
                text: 'title',
                value: 'title',
              },
              {
                text: 'published',
                value: 'published',
              },
              {
                text: 'updated',
                value: 'updated',
              },
              {
                text: 'author.name',
                value: 'author.name',
              },
              {
                text: 'extensions.section',
                value: 'extensions.section',
              },
              {
                text: 'other',
                value: 'other',
              },
            ],
            initial_value: 'extensions.section',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_1_custom_data_key',
            label: 'Custom data key',
            conditional_fields: [
              {
                key: 'styles/text_label_1_data_key',
                condition_value: 'other',
              },
            ],
            initial_value: null,
            type: 'text_input',
          },
          {
            key: 'text_label_1_typography',
            label: 'HTML element',
            label_tooltip:
              'Choose HTML element to be rendered for the text label',
            options: [
              {
                text: 'Heading 1',
                value: 'h1',
              },
              {
                text: 'Heading 2',
                value: 'h2',
              },
              {
                text: 'Heading 3',
                value: 'h3',
              },
              {
                text: 'Heading 4',
                value: 'h4',
              },
              {
                text: 'Heading 5',
                value: 'h5',
              },
              {
                text: 'Heading 6',
                value: 'h6',
              },
              {
                text: 'Paragraph',
                value: 'p',
              },
              {
                text: 'Block',
                value: 'div',
              },
            ],
            initial_value: 'p',
            type: 'select',
          },
          {
            key: 'text_label_1_font_color',
            label: 'Font color',
            rules: 'conditional',
            initial_value: 'rgba(4, 207, 153, 1)',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_font_color_focused',
            label: 'Focused Font color',
            rules: 'conditional',
            initial_value: 'rgba(4, 207, 153, 1)',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_family_font_selector',
            label: 'Font Family',
            initial_value: 'SFProText-Bold',
            placeholder: 'Select font',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_1_font_size',
            label: 'Font size',
            rules: 'conditional',
            initial_value: 11,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_line_height',
            label: 'Line height',
            rules: 'conditional',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_letter_spacing',
            label: 'Letter spacing',
            rules: 'conditional',
            initial_value: -0.2,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_text_alignment',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
              {
                text: 'Center',
                value: 'center',
              },
            ],
            rules: 'conditional',
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_1_text_transform',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            rules: 'conditional',
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_1_number_of_lines',
            label: 'Number of lines',
            rules: 'conditional',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_top',
            label: 'Padding top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_right',
            label: 'Padding right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_bottom',
            label: 'Padding bottom',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_padding_left',
            label: 'Padding left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_1_background_color',
            label: 'Background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_background_color_focused',
            label: 'Focused background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_1_corner_radius',
            label: 'Corner radius',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_1_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Text label 2',
        folded: false,
        fields: [
          {
            key: 'text_label_2_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'text_label_2_data_key',
            label: 'Data key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            rules: 'conditional',
            options: [
              {
                text: 'summary',
                value: 'summary',
              },
              {
                text: 'title',
                value: 'title',
              },
              {
                text: 'published',
                value: 'published',
              },
              {
                text: 'updated',
                value: 'updated',
              },
              {
                text: 'author.name',
                value: 'author.name',
              },
              {
                text: 'extensions.section',
                value: 'extensions.section',
              },
              {
                text: 'other',
                value: 'other',
              },
            ],
            initial_value: 'title',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_2_custom_data_key',
            label: 'Custom data key',
            conditional_fields: [
              {
                key: 'styles/text_label_2_data_key',
                condition_value: 'other',
              },
            ],
            initial_value: null,
            type: 'text_input',
          },
          {
            key: 'text_label_2_typography',
            label: 'HTML element',
            label_tooltip:
              'Choose HTML element to be rendered for the text label',
            options: [
              {
                text: 'Heading 1',
                value: 'h1',
              },
              {
                text: 'Heading 2',
                value: 'h2',
              },
              {
                text: 'Heading 3',
                value: 'h3',
              },
              {
                text: 'Heading 4',
                value: 'h4',
              },
              {
                text: 'Heading 5',
                value: 'h5',
              },
              {
                text: 'Heading 6',
                value: 'h6',
              },
              {
                text: 'Paragraph',
                value: 'p',
              },
              {
                text: 'Block',
                value: 'div',
              },
            ],
            initial_value: 'p',
            type: 'select',
          },
          {
            key: 'text_label_2_font_color',
            label: 'Font color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 1)',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_font_color_focused',
            label: 'Focused Font color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 1)',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_family_font_selector',
            label: 'Font Family',
            initial_value: 'SFProText-Bold',
            placeholder: 'Select font',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_2_font_size',
            label: 'Font size',
            rules: 'conditional',
            initial_value: 15,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_line_height',
            label: 'Line height',
            rules: 'conditional',
            initial_value: 20,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_letter_spacing',
            label: 'Letter spacing',
            rules: 'conditional',
            initial_value: -0.4,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_text_alignment',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
              {
                text: 'Center',
                value: 'center',
              },
            ],
            rules: 'conditional',
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_2_text_transform',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            rules: 'conditional',
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_2_number_of_lines',
            label: 'Number of lines',
            rules: 'conditional',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: 2,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_top',
            label: 'Padding top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_right',
            label: 'Padding right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_bottom',
            label: 'Padding bottom',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_padding_left',
            label: 'Padding left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_2_background_color',
            label: 'Background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_background_color_focused',
            label: 'Focused background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_2_corner_radius',
            label: 'Corner radius',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_2_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Text label 3',
        folded: false,
        fields: [
          {
            key: 'text_label_3_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'text_label_3_data_key',
            label: 'Data key',
            tooltip:
              'What data key from feed entry should be rendered in this field.',
            rules: 'conditional',
            options: [
              {
                text: 'summary',
                value: 'summary',
              },
              {
                text: 'title',
                value: 'title',
              },
              {
                text: 'published',
                value: 'published',
              },
              {
                text: 'updated',
                value: 'updated',
              },
              {
                text: 'author.name',
                value: 'author.name',
              },
              {
                text: 'extensions.section',
                value: 'extensions.section',
              },
              {
                text: 'other',
                value: 'other',
              },
            ],
            initial_value: 'summary',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_3_custom_data_key',
            label: 'Custom data key',
            conditional_fields: [
              {
                key: 'styles/text_label_3_data_key',
                condition_value: 'other',
              },
            ],
            initial_value: null,
            type: 'text_input',
          },
          {
            key: 'text_label_3_typography',
            label: 'HTML element',
            label_tooltip:
              'Choose HTML element to be rendered for the text label',
            options: [
              {
                text: 'Heading 1',
                value: 'h1',
              },
              {
                text: 'Heading 2',
                value: 'h2',
              },
              {
                text: 'Heading 3',
                value: 'h3',
              },
              {
                text: 'Heading 4',
                value: 'h4',
              },
              {
                text: 'Heading 5',
                value: 'h5',
              },
              {
                text: 'Heading 6',
                value: 'h6',
              },
              {
                text: 'Paragraph',
                value: 'p',
              },
              {
                text: 'Block',
                value: 'div',
              },
            ],
            initial_value: 'p',
            type: 'select',
          },
          {
            key: 'text_label_3_font_color',
            label: 'Font color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0.8)',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_font_color_focused',
            label: 'Focused Font color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0.8)',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_family_font_selector',
            label: 'Font Family',
            initial_value: 'SFProText-Medium',
            placeholder: 'Select font',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'web_font_selector',
          },
          {
            key: 'text_label_3_font_size',
            label: 'Font size',
            rules: 'conditional',
            initial_value: 13,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_line_height',
            label: 'Line height',
            rules: 'conditional',
            initial_value: 18,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_letter_spacing',
            label: 'Letter spacing',
            rules: 'conditional',
            initial_value: -0.4,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_text_alignment',
            label: 'Text alignment',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
              {
                text: 'Center',
                value: 'center',
              },
            ],
            rules: 'conditional',
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_3_text_transform',
            label: 'Text transform',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            rules: 'conditional',
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'text_label_3_number_of_lines',
            label: 'Number of lines',
            rules: 'conditional',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_top',
            label: 'Padding top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_right',
            label: 'Padding right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_bottom',
            label: 'Padding bottom',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_padding_left',
            label: 'Padding left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'text_label_3_background_color',
            label: 'Background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_background_color_focused',
            label: 'Focused background color',
            rules: 'conditional',
            initial_value: 'rgba(255, 255, 255, 0)',
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'text_label_3_corner_radius',
            label: 'Corner radius',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/text_label_3_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
    ],
  },
  assets: {
    fields: [
      {
        group: true,
        label: 'Badges',
        folded: false,
        fields: [
          {
            key: 'content_badge_switch',
            label: 'enable',
            initial_value: true,
            type: 'switch',
          },
          {
            key: 'static_badge',
            label: 'Static badge',
            rules: 'conditional',
            initial_value: null,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'program_badge',
            label: 'Program badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'feed_badge',
            label: 'Feed badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'link_badge',
            label: 'Link badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'article_badge',
            label: 'Article badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'channel_badge',
            label: 'Channel badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'video_badge',
            label: 'Video badge',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_1',
            label: 'Custom content type 1',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_1',
            label: 'Custom badge 1',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_2',
            label: 'Custom content type 2',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_2',
            label: 'Custom badge 2',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_type_custom_badge_3',
            label: 'Custom content type 3',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'custom_badge_3',
            label: 'Custom badge 3',
            rules: 'conditional',
            initial_value: '',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'content_badge_position',
            label: 'Badge position',
            options: [
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Top Left',
                value: 'top_left',
              },
              {
                text: 'Top Right',
                value: 'top_right',
              },
              {
                text: 'Bottom Left',
                value: 'bottom_left',
              },
              {
                text: 'Bottom Right',
                value: 'bottom_right',
              },
            ],
            rules: 'conditional',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            initial_value: 'center',
            type: 'select',
          },
          {
            key: 'content_badge_width',
            label: 'Width',
            initial_value: '44',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_height',
            label: 'Height',
            initial_value: '44',
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'content_badge_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/content_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Lock badge',
        folded: false,
        fields: [
          {
            key: 'lock_badge_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'locked_badge',
            label: 'Locked badge',
            rules: 'conditional',
            label_tooltip:
              'The lock badge will show when the data key is false',
            initial_value:
              'https://assets-production.applicaster.com/applicaster-employees/zapp_team/assets/cells/lock_icon.png',
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'unlocked_badge',
            label: 'Unlocked badge',
            label_tooltip:
              'The unlock badge will show when the data key is true',
            rules: 'conditional',
            initial_value:
              'https://assets-production.applicaster.com/applicaster-employees/zapp_team/assets/cells/unlock_icon.png',
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'lock_badge_position',
            label: 'Badge position',
            options: [
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Top Left',
                value: 'top_left',
              },
              {
                text: 'Top Right',
                value: 'top_right',
              },
              {
                text: 'Bottom Left',
                value: 'bottom_left',
              },
              {
                text: 'Bottom Right',
                value: 'bottom_right',
              },
            ],
            initial_value: 'top_left',
            rules: 'conditional',
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'lock_badge_data_key',
            initial_value: 'extensions.free',
            label: 'Lock badge data key',
            placeholder: 'extensions.free',
            label_tooltip:
              'Specify path to key that will determine whether the\n               content should be locked or not. i.e.extensions.isFree, if true unlock.\n               If the key does not exist in the entry, no badge will show',
            rules: 'conditional',
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'lock_badge_width',
            label: 'Width',
            initial_value: 44,
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_height',
            label: 'Height',
            initial_value: 44,
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_top',
            label: 'Margin top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_right',
            label: 'Margin right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'lock_badge_margin_left',
            label: 'Margin left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/lock_badge_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Action Button',
        folded: false,
        fields: [
          {
            key: 'action_switch',
            label: 'enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'action_identifier',
            label: 'Action plugin identifier',
            initial_value: 'local_storage_favourites_action',
            placeholder: 'plugin_identifier',
            label_tooltip:
              "Action button requires an action plugin, please provide the plugin's id",
            rules: 'conditional',
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'text_input',
          },
          {
            key: 'selected_action_icon',
            label: 'Selected action icon',
            rules: 'conditional',
            initial_value:
              'https://assets-secure.applicaster.com/zapp/assets/app_family/1710/974543899588/fav.png',
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'unselected_action_icon',
            label: 'Unselected action icon',
            rules: 'conditional',
            initial_value:
              'https://assets-secure.applicaster.com/zapp/assets/app_family/1710/753243765951/unfav.png',
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'uploader',
          },
          {
            key: 'action_asset_flavour',
            label: 'Action asset flavour',
            label_tooltip:
              'Some actions offer two flavours of assets in their configuration so the button appears nicely over different styles of background. This option lets you select the flavour you want to use',
            options: [
              {
                text: 'Flavour 1',
                value: 'flavour_1',
              },
              {
                text: 'Flavour 2',
                value: 'flavour_2',
              },
            ],
            initial_value: 'flavour_1',
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'action_position',
            label: 'Button position',
            options: [
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Top Left',
                value: 'top_left',
              },
              {
                text: 'Top Right',
                value: 'top_right',
              },
              {
                text: 'Bottom Left',
                value: 'bottom_left',
              },
              {
                text: 'Bottom Right',
                value: 'bottom_right',
              },
            ],
            initial_value: 'top_right',
            rules: 'conditional',
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
          {
            key: 'action_width',
            label: 'Width',
            initial_value: 44,
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'action_height',
            label: 'Height',
            initial_value: 44,
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_top',
            label: 'Margin top',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_right',
            label: 'Margin right',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_bottom',
            label: 'Margin bottom',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'action_margin_left',
            label: 'Margin left',
            rules: 'conditional',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/action_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Image',
        folded: false,
        fields: [
          {
            key: 'image_key',
            label: 'Image key',
            initial_value: 'image_base',
            type: 'text_input',
          },
          {
            key: 'image_aspect_ratio',
            label: 'Image Ratio',
            label_tooltip:
              'Chose the aspect ratio for your images, 16x9 is default',
            options: [
              {
                value: '1x1',
                text: '1x1',
              },
              {
                value: '2x3',
                text: '2x3',
              },
              {
                value: '3x2',
                text: '3x2',
              },
              {
                value: '3x4',
                text: '3x4',
              },
              {
                value: '4x3',
                text: '4x3',
              },
              {
                value: '16x9',
                text: '16x9',
              },
              {
                value: 'other',
                text: 'other',
              },
            ],
            initial_value: '16x9',
            type: 'select',
          },
          {
            key: 'image_aspect_ratio_custom',
            label: 'Custom aspect ratio',
            placeholder: '16x10',
            label_tooltip:
              "Enter a custom aspect ratio for the cell image like - '16x10'",
            conditional_fields: [
              {
                key: 'assets/image_aspect_ratio',
                condition_value: 'other',
              },
            ],
            initial_value: '16x10',
            type: 'text_input',
          },
          {
            key: 'image_placeholder_color',
            label: 'Placeholder color',
            initial_value: 'rgba(36, 41, 54, 1)',
            type: 'color_picker_rgba',
          },
          {
            key: 'image_placeholder_image',
            label: 'Placeholder image',
            initial_value: '',
            type: 'uploader',
          },
          {
            key: 'image_overlay',
            label: 'Overlay image',
            initial_value: '',
            type: 'uploader',
          },
          {
            key: 'image_corner_radius',
            label: 'Corner radius',
            initial_value: '0',
            type: 'number_input',
          },
          {
            key: 'image_margin_top',
            label: 'Margin top',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_margin_right',
            label: 'Margin right',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_margin_bottom',
            label: 'Margin bottom',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_margin_left',
            label: 'Margin left',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_shadow_color',
            label: 'Shadow color',
            initial_value: 'rgba(0, 0, 0, 0)',
            type: 'color_picker_rgba',
          },
          {
            key: 'image_shadow_opacity',
            label: 'Shadow opacity',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_shadow_offset_width',
            label: 'Shadow offset width',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_shadow_offset_height',
            label: 'Shadow offset height',
            initial_value: 0,
            type: 'number_input',
          },
          {
            key: 'image_shadow_radius',
            label: 'Shadow radius',
            initial_value: 0,
            type: 'number_input',
          },
        ],
      },
      {
        group: true,
        label: 'Progress Bar',
        folded: false,
        fields: [
          {
            key: 'progress_bar_switch',
            label: 'Enable',
            initial_value: false,
            type: 'switch',
          },
          {
            key: 'progress_bar_hide_unwatched',
            rules: 'all_conditions',
            label: 'Hide Unwatched',
            initial_value: true,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'switch',
          },
          {
            key: 'progress_bar_total_bar_color',
            rules: 'all_conditions',
            label: 'Total Bar Color',
            initial_value: 'rgba(255, 255, 255, 0.25)',
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'progress_bar_total_bar_corner_radius',
            rules: 'all_conditions',
            label: 'Total Bar Corner Radius',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_progress_bar_color',
            rules: 'all_conditions',
            label: 'Progress Bar Color',
            initial_value: 'rgba(255, 255, 255, 1)',
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'color_picker_rgba',
          },
          {
            key: 'progress_bar_progress_bar_corner_radius',
            rules: 'all_conditions',
            label: 'Progress Bar Corner Radius',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_height',
            rules: 'all_conditions',
            label: 'Height',
            initial_value: 5,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_top',
            rules: 'all_conditions',
            label: 'Margin Top',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_right',
            rules: 'all_conditions',
            label: 'Margin Right',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_bottom',
            rules: 'all_conditions',
            label: 'Margin Bottom',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_margin_left',
            rules: 'all_conditions',
            label: 'Margin Left',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'number_input',
          },
          {
            key: 'progress_bar_position',
            options: [
              {
                text: 'On Top of Image',
                value: 'on_top_of_image',
              },
              {
                text: 'Above Text Label 1',
                value: 'above_text_label_1',
              },
              {
                text: 'Below Text Label 1',
                value: 'below_text_label_1',
              },
              {
                text: 'Above Text Label 2',
                value: 'above_text_label_2',
              },
              {
                text: 'Below Text Label 2',
                value: 'below_text_label_2',
              },
              {
                text: 'Above Text Label 3',
                value: 'above_text_label_3',
              },
              {
                text: 'Below Text Label 3',
                value: 'below_text_label_3',
              },
            ],
            rules: 'all_conditions',
            label: 'Position',
            initial_value: 'on_top_of_image',
            conditional_fields: [
              {
                key: 'assets/progress_bar_switch',
                condition_value: true,
              },
            ],
            type: 'select',
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
