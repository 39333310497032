import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramImage,
  ProgramItem,
  ProgramStack,
  ProgramText,
  ProgramTitle,
  useProgram,
} from 'planby-mit';
import { MaybeLink } from '../maybe-link';
import { twMerge } from 'tailwind-merge';

type ProgramItemExtended = ProgramItem & {
  uiComponent: any;
  webLink?: string;
};

export function EpgItem({
  program,
  uiComponent,
  ...rest
}: ProgramItemExtended): JSX.Element {
  const { styles, formatTime, isMinWidth, isLive } = useProgram({
    program,
    ...rest,
  });

  const { data } = program;

  const { image, title, since, till, webLink } = data;

  const sinceTime: string = formatTime(since);
  const tillTime: string = formatTime(till);
  const isWebLink: boolean = !!webLink;
  const titleSwitch: boolean = uiComponent.styles?.epg_item_label_1_switch;

  return (
    <span className={twMerge(!isWebLink && 'pointer-events-none')}>
      <MaybeLink
        to={webLink || undefined}
        linkariainfo={title}
        analyticsdata={undefined}
      >
        <ProgramBox width={styles.width} style={styles.position}>
          <ProgramContent width={styles.width} isLive={isLive}>
            <ProgramFlex>
              {isLive && isMinWidth && (
                <ProgramImage src={image} alt="Preview" />
              )}
              <ProgramStack>
                {titleSwitch && (
                  <ProgramTitle>
                    <span
                      className={twMerge(
                        'font-epg_item_label_1_font_family text-epg_item_label_1_font_size tracking-epg_item_label_1_letter_spacing epg-item-label-1-text-transform'
                      )}
                    >
                      <span
                        className={twMerge(
                          isLive && 'text-epg_item_label_1_live_font_color'
                        )}
                      >
                        {title}
                      </span>
                    </span>
                  </ProgramTitle>
                )}
                <ProgramText>
                  <span
                    className={twMerge(
                      'font-epg_item_label_2_font_family text-epg_item_label_2_font_size -tracking-epg_item_label_2_letter_spacing'
                    )}
                  >
                    <span
                      className={twMerge(
                        'text-epg_item_label_2_font_color',
                        isLive && 'text-epg_item_label_2_live_font_color'
                      )}
                    >
                      {sinceTime} - {tillTime}
                    </span>
                  </span>
                </ProgramText>
              </ProgramStack>
            </ProgramFlex>
          </ProgramContent>
        </ProgramBox>
      </MaybeLink>
    </span>
  );
}
