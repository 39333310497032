import { useFetcher } from '@remix-run/react';
import { useDebounce } from './use-debounce';

const TRAILER_LINK_DEBOUNCE_DELAY: number = 1000;

export function useTrailerLink({
  entryTrailerLink,
}: {
  entryTrailerLink: string;
}): {
  debouncedTrailerLinkAction: () => void;
} {
  const fetcher = useFetcher({
    key: 'trailer-link',
  });

  const submitTrailerLinkRequest = () => {
    fetcher.submit(
      { entryTrailerLink },
      {
        method: 'post',
        action: '/api/trailer-link',
      }
    );
  };

  const debouncedTrailerLinkAction = useDebounce(
    submitTrailerLinkRequest,
    TRAILER_LINK_DEBOUNCE_DELAY
  );

  return { debouncedTrailerLinkAction };
}
