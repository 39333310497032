import logger from '~/services/logger';
import type {
  Feed,
  FeedEntry,
  UIComponent,
} from '~/services/layout/index.server.ts';
import { PowerCell } from '../../power-cell/components/index.tsx';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';
import manifest from '../config/manifest.ts';

export default function HeroV2({
  uiComponent,
  feed,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
}): JSX.Element {
  const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

  const selectable = !!config.rules.component_cells_selectable;

  try {
    const firstEntry: FeedEntry | undefined = feed?.entry?.[0];

    if (!firstEntry) return <></>;

    return (
      <section
        className={`component-${uiComponent.id} component-classes relative h-fit w-full overflow-hidden border-none`}
      >
        <PowerCell
          uiComponent={uiComponent}
          entry={firstEntry}
          selectable={selectable}
        />
      </section>
    );
  } catch (error: any) {
    logger.info(`Hero V2: ${error.message}`);
    return <></>;
  }
}
