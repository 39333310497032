import { useEffect, useRef } from 'react';
import { useLocation } from '@remix-run/react';
import { track } from '~/services/analytics/index.client.ts';

export function useScreenAnalytics(analyticsTrackData: {
  [key: string]: any;
}): void {
  const startTime = useRef<any>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      startTime.current = new Date().getTime();
      track('screen_view', analyticsTrackData);
    }

    return () => {
      if (location.pathname) {
        const duration = (new Date().getTime() - startTime.current) / 1000;
        track('time_on_screen', {
          ...analyticsTrackData,
          duration,
        });
      }
    };
  }, [location.pathname, analyticsTrackData]);
}
