const manifest = {
  plugin_id: 1313,
  identifier: 'webview_component',
  manifest_version: '0.0.1-alpha.4',
  dependency_name: '',
  name: 'Webview component',
  description:
    'A webview component plugin that allows you to render a webpage within iframe inside the screen of the application',
  type: 'ui_component',
  api: {},
  dependency_repository_url: [],
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  screen: false,
  react_native: true,
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  min_zapp_sdk: '0.0.1',
  styles: {
    fields: [
      {
        type: 'number_input',
        key: 'webview_height',
        label: 'Webview height',
        initial_value: 100,
      },
      {
        type: 'number_input',
        key: 'component_mobile_margin_top',
        label: 'Webview mobile margin top',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_tablet_margin_top',
        label: 'Webview tablet margin top',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_desktop_margin_top',
        label: 'Webview desktop margin top',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_large_desktop_margin_top',
        label: 'Webview large desktop margin top',
        initial_value: null,
      },

      {
        type: 'number_input',
        key: 'component_mobile_margin_bottom',
        label: 'Webview mobile margin bottom',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_tablet_margin_bottom',
        label: 'Webview tablet margin bottom',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_desktop_margin_bottom',
        label: 'Webview desktop margin bottom',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_large_desktop_margin_bottom',
        label: 'Webview large desktop margin bottom',
        initial_value: null,
      },

      {
        type: 'number_input',
        key: 'component_mobile_padding_bottom',
        label: 'Webview mobile padding bottom',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_tablet_padding_bottom',
        label: 'Webview tablet padding bottom',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_desktop_padding_bottom',
        label: 'Webview desktop padding bottom',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_large_desktop_padding_bottom',
        label: 'Webview large desktop padding bottom',
        initial_value: null,
      },

      {
        type: 'number_input',
        key: 'component_mobile_padding_right',
        label: 'Webview mobile padding right',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_tablet_padding_right',
        label: 'Webview tablet padding right',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_desktop_padding_right',
        label: 'Webview desktop padding right',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_large_desktop_padding_right',
        label: 'Webview large desktop padding right',
        initial_value: null,
      },

      {
        type: 'number_input',
        key: 'component_mobile_padding_top',
        label: 'Webview mobile padding top',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_tablet_padding_top',
        label: 'Webview tablet padding top',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_desktop_padding_top',
        label: 'Webview desktop padding top',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_large_desktop_padding_top',
        label: 'Webview large desktop padding top',
        initial_value: null,
      },

      {
        type: 'number_input',
        key: 'component_mobile_padding_left',
        label: 'Webview mobile padding left',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_tablet_padding_left',
        label: 'Webview tablet padding left',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_desktop_padding_left',
        label: 'Webview desktop padding left',
        initial_value: null,
      },
      {
        type: 'number_input',
        key: 'component_large_desktop_padding_left',
        label: 'Webview large desktop padding left',
        initial_value: null,
      },
    ],
  },
  rules: {
    fields: [],
  },
  data: {
    fields: [
      {
        key: 'url',
        type: 'text_input',
        label: 'Webview source url',
        initial_value: null,
      },
      {
        key: 'webview_accessibility_title',
        label: 'Webview Accessibility Title',
        type: 'text_input',
        initial_value: null,
      },
    ],
  },
  characteristics: {
    group: false,
    appearance: ['group', 'screen'],
    disable_default_cell_style: true,
  },
} as const;

export default manifest;
