import { twMerge } from 'tailwind-merge';
import get from 'lodash.get';

import { getPayloadImgSrc } from '~/services/layout/layout.ts';
import Label from '../label.tsx';
import { MaybeLink } from '../maybe-link.tsx';
import { cellTextLabels } from '~/services/layout/text-labels.ts';
import { ActionIcon } from '~/components/cell-components/action-icon.tsx';
import Runtime from '../cell-components/runtime.tsx';
import PayloadImage from '../cell-components/payload-image.tsx';
import ProgressBar from '../cell-components/progress-bar.tsx';

import type { CellProps } from './index.tsx';

function Cell3({
  cellId,
  entry,
  cellConfig,
  isClient,
  progress,
  linkTo,
  cellStyles,
  useImageLoading,
  isCircularScrolling,
  selectable,
  cellRef,
  analyticsData,
}: CellProps) {
  const {
    top_text_label1,
    top_text_label2,
    top_text_label3,

    center_text_label1,
    center_text_label2,
    center_text_label3,

    bottom_text_label1,
    bottom_text_label2,
    bottom_text_label3,
  } = cellTextLabels(entry, cellConfig);

  const overlaySrc: string | undefined = get(
    cellStyles[cellId],
    'configuration.assets.image_overlay'
  );

  const imageSrc: string | undefined = getPayloadImgSrc(
    cellConfig?.configuration?.assets,
    entry
  );

  return (
    <MaybeLink
      to={linkTo}
      enabled={selectable}
      linkariainfo={entry?.title}
      analyticsdata={analyticsData}
    >
      <div
        ref={cellRef}
        className={twMerge(
          `cell-${cellId} relative shrink-0 rounded-cell-radius`,
          isCircularScrolling ? 'w-[100%]' : 'w-cell',
          isClient && 'animate-cell-scale'
        )}
      >
        <div className="relative bg-transparent">
          <Runtime
            entry={entry}
            enabled={cellConfig?.configuration?.styles?.runtime_switch}
          />
          <ActionIcon cellConfig={cellConfig} entry={entry} />
          <PayloadImage
            imageSrc={imageSrc}
            entryTitle={entry?.title}
            classNames="aspect-cell-image w-full"
            useImageLoading={useImageLoading}
          />
        </div>
        {overlaySrc && (
          <img
            alt={entry.title}
            className="absolute left-0 top-0 aspect-cell-image w-full"
            src={overlaySrc}
          />
        )}
        <div className="absolute left-0 top-0 w-full">
          <Label
            className={`cell-${cellId}-top_text_label_1`}
            text={top_text_label1}
          />
          <Label
            className={`cell-${cellId}-top_text_label_2`}
            text={top_text_label2}
          />
          <Label
            className={`cell-${cellId}-top_text_label3`}
            text={top_text_label3}
          />
        </div>

        <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform">
          <Label
            className={`cell-${cellId}-center_text_label_1`}
            text={center_text_label1}
          />
          <Label
            className={`cell-${cellId}-center_text_label_2`}
            text={center_text_label2}
          />
          <Label
            className={`cell-${cellId}-center_text_label_3`}
            text={center_text_label3}
          />
        </div>

        <div className="absolute bottom-0 left-0 w-full">
          <Label
            className={`cell-${cellId}-bottom_text_label_1`}
            text={bottom_text_label1}
          />
          <Label
            className={`cell-${cellId}-bottom_text_label_2`}
            text={bottom_text_label2}
          />
          <Label
            className={`cell-${cellId}-bottom_text_label_3`}
            text={bottom_text_label3}
          />
        </div>
        <ProgressBar
          progress={progress}
          enabled={cellConfig?.configuration?.assets?.progress_bar_switch}
        />
      </div>
    </MaybeLink>
  );
}

export default Cell3;
