import { useLoaderData } from '@remix-run/react';
import React from 'react';

import DebugComponent from '~/components/debug.tsx';
import UiComponent from '~/components/ui-component.tsx';
import Search from '~/components/screens/search.tsx';

import type { loader as rootLoader } from '~/routes/_index.tsx';
import { twMerge } from 'tailwind-merge';

export default function GeneralContent(): JSX.Element {
  const {
    debug,
    serverLoadedFeeds,
    uiComponents,
    isSearchScreen,
    screen,
    isThemeV2,
  } = useLoaderData<typeof rootLoader>();

  return (
    <div
      className={twMerge(
        `screen-${screen.id}`,
        'z-0',
        isThemeV2
          ? 'screen-classes'
          : 'mb-screen-b mt-screen-t min-h-[50vh] bg-screen pb-mobile-screen-b pl-mobile-screen-l pr-mobile-screen-r pt-mobile-screen-t lg:pb-screen-b lg:pl-screen-l lg:pr-screen-r lg:pt-screen-t'
      )}
    >
      {isSearchScreen && <Search />}

      {React.Children.toArray(
        uiComponents.map((uiComponent: any) => {
          const feed = serverLoadedFeeds.find(
            (feed: any) => feed.componentId === uiComponent.id
          )?.feed;

          const error = serverLoadedFeeds.find(
            (feed: any) => feed.componentId === uiComponent.id
          )?.error;

          return (
            <div className="truncate">
              {debug === 'on' && (
                <DebugComponent
                  uiComponent={uiComponent}
                  feed={feed}
                  error={error}
                />
              )}
              {/* @ts-ignore */}
              <UiComponent uiComponent={uiComponent} feed={feed} />
            </div>
          );
        })
      )}
    </div>
  );
}
