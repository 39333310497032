import { useReadyState } from '~/hooks/use-ready-state.ts';
import logger from '~/services/logger';

export function Cell1Skeleton({ cellId }: { cellId: string }) {
  const isDocumentReady = useReadyState();

  return (
    <>
      {isDocumentReady && (
        <div
          className={`cell-${cellId} w-cell shrink-0 animate-cell-scale rounded-cell-radius`}
        >
          <div className="relative bg-transparent">
            <div
              className={`aspect-cell-image w-full animate-pulse rounded-image-radius bg-skeleton_background_color`}
            ></div>
            <div>
              <div
                className={`mt-1 h-4 w-28 animate-pulse rounded-sm bg-skeleton_background_color`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function CellInfoSkeleton() {
  const isDocumentReady = useReadyState();

  return (
    <>
      {isDocumentReady && (
        <div
          className={`mb-2 h-6 w-36 animate-pulse rounded-sm bg-skeleton_background_color`}
        />
      )}
    </>
  );
}

export function EPGLoadingSkeleton({
  loadingSkeletonItemHeight,
  channelSidebarWidth,
  numChannels,
  uiComponentId,
}: {
  loadingSkeletonItemHeight: number;
  channelSidebarWidth: number;
  numChannels: number;
  uiComponentId: any;
}) {
  try {
    const channels: number[] = Array.from(
      { length: numChannels },
      (_, index) => index + 1
    );

    const height: string = `${loadingSkeletonItemHeight}px`;
    const width: string = `${channelSidebarWidth}px`;

    return (
      <div
        className={`component-${uiComponentId} bg-channel_item_background_color`}
        style={{
          padding: '8px',
          paddingTop: '65px',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          zIndex: 200,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
          }}
        >
          {channels.map((channel) => (
            <div
              key={channel}
              style={{
                width,
                height,
                marginBottom: '10px',
              }}
              className="animate-pulse rounded-sm bg-skeleton_background_color"
            ></div>
          ))}
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {channels.map((channel) => (
            <div
              key={channel}
              style={{
                height,
                marginBottom: '10px',
              }}
              className="animate-pulse rounded-sm bg-skeleton_background_color"
            ></div>
          ))}
        </div>
      </div>
    );
  } catch (error: any) {
    logger.info(`Error rendering epg loading skeleton: ${error.message}`);
    return <></>;
  }
}
