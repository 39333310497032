import React, { useRef, useState } from 'react';
import type {
  Feed,
  FeedEntry,
  UIComponent,
} from '~/services/layout/index.server.ts';
import { getDynamicFeedEntries, getLimit } from '~/services/layout/layout.ts';
import { getPluginIdentifier } from '~/services/ui-components/index.ts';
import { useLoaderData } from '@remix-run/react';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import { useScreenPadding } from '~/hooks/use-screen-padding.ts';
import { twMerge } from 'tailwind-merge';
import { HorizontalListButton } from './button.tsx';
import { Cell } from '~/components/cell/index.tsx';
import {
  useDispachResizeEvent,
  useHorizontalListCircular,
  useHorizontalListResize,
  useHorizontalListScroll,
} from './hooks.ts';
import { useNextFeedPagination } from '~/hooks/use-next-feed.ts';
import { useFeedEntriesV1 } from '~/hooks/use-feed-entries.ts';
import { useFeedEntriesOnSearchQueryChange } from '~/hooks/use-feed-entries-on-search.ts';
import { getTranslations } from '~/services/localization/index.ts';

//#region Horizontal List Component
export default function HorizontalList({
  uiComponent,
  feed,
  isLoading,
  isClient = false,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
}): JSX.Element {
  // The Horizontal List scrollable container
  const ref = useRef<any>(null);
  // The Horizontal List <Cell> component
  const cellRef = useRef<any>(null);

  const { cellStyles } = useLoaderData<typeof rootLoader>();

  const limit: number | undefined = getLimit(uiComponent?.rules?.item_limit);
  const initialFeedEntries: FeedEntry[] | [] =
    feed?.entry?.slice(0, limit) || [];
  const entries: FeedEntry[] | [] = isLoading
    ? new Array(10).fill({})
    : initialFeedEntries;

  // Buttons
  const [toggleButtons, setToggleButtons] = useState<{
    enabled: boolean;
    left: boolean;
    right: boolean;
    now: number;
  }>({
    enabled: false,
    left: false,
    right: false,
    now: new Date().getTime(),
  });

  const [feedEntries, setFeedEntries] = useState<FeedEntry[]>([]);

  useFeedEntriesV1({
    initialFeedEntries,
    setFeedEntries,
  });

  const dynamicFeedEntries: FeedEntry[] | [] = getDynamicFeedEntries({
    feedEntries,
    entries,
    isClient: !!isClient,
  });

  useFeedEntriesOnSearchQueryChange({
    setFeedEntries,
    entries,
  });

  // Next feed
  const [nextFeedUrl, setNextFeedUrl] = useState<string | undefined>(undefined);
  const [isNextFeedLoading, setIsNextFeedLoading] = useState<boolean>(false);

  // Cirular
  const [cellIndex, setCellIndex] = useState<number>(0);
  const [isCircularStarted, setIsCircularStarted] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const screenPadding: number = useScreenPadding();

  useNextFeedPagination({
    feed,
    nextFeedUrl,
    setNextFeedUrl,
    setIsNextFeedLoading,
    isNextFeedLoading,
    setFeedEntries,
    feedEntries,
    condition: !!nextFeedUrl && toggleButtons.enabled && !toggleButtons.right,
    fireOnLoading: () => {
      setToggleButtons((prevToggleButtons: any) => ({
        ...prevToggleButtons,
        right: true,
      }));
    },
    isLoading,
    isLoadingSkeletonsV2: false,
  });

  useHorizontalListCircular({
    uiComponent,
    ref,
    cellRef,
    toggleButtons,
    isClient,
    screenPadding,
    nextFeedUrl,
    setFeedEntries,
    feedEntries,
    cellIndex,
    isCircularStarted,
    setIsCircularStarted,
    isScrolling,
  });

  useHorizontalListResize({
    entries,
    ref,
    cellRef,
    nextFeedUrl,
    isCircularStarted,
    setToggleButtons,
  });

  useDispachResizeEvent({
    ref,
    isCircularStarted,
    nextFeedUrl,
  });

  useHorizontalListScroll({
    ref,
    cellRef,
    isCircularStarted,
    isScrolling,
    setIsScrolling,
    setToggleButtons,
    setCellIndex,
    nextFeedUrl,
  });

  // <Cell> component attributes
  const cellId: string = uiComponent?.styles?.cell_plugin_configuration_id;
  const pluginIdentifier: string | undefined = getPluginIdentifier(
    cellStyles,
    cellId
  );
  const feedPath: string | undefined = feed?._feedPath;
  const selectable = uiComponent?.rules?.component_cells_selectable;

  const translations = getTranslations(uiComponent.localizations);

  const titleOverride: string | undefined = translations?.title_override;

  return (
    <div
      className={`component-${uiComponent?.id} mb-component-b ml-component-l mr-component-r mt-component-t bg-component pb-component-b pl-component-l pr-component-r pt-component-t`}
    >
      <div className="max-w-screen relative items-center overflow-hidden overflow-x-hidden">
        <section
          className={`max-w-screen flex h-fit w-screen shrink-0 snap-x snap-mandatory flex-row gap-component overflow-hidden overflow-x-auto scroll-smooth scrollbar-hide`}
          ref={ref}
          style={{ paddingRight: `${screenPadding}px` }}
          data-testid={`horizontal-list-container-${uiComponent?.id}`}
        >
          <HorizontalListButton
            direction="left"
            showBtn={toggleButtons.enabled && toggleButtons.left}
            uiComponent={uiComponent}
            continaerRef={ref}
            cellRef={cellRef}
            screenPadding={screenPadding}
            setToggleButtons={setToggleButtons}
            isNextFeedLoading={isNextFeedLoading}
          />

          {React.Children.toArray(
            dynamicFeedEntries?.map((entry: any) => {
              const title: string = titleOverride || entry?.title;
              const cellEntry = { ...entry, title };

              return (
                <>
                  <div
                    className={twMerge(
                      `cell-${cellId} snap-both snap-always transition-all duration-500`
                    )}
                  >
                    <Cell
                      isLoading={isLoading}
                      isClient={isClient}
                      cellId={cellId}
                      entry={cellEntry}
                      pluginIdentifier={pluginIdentifier}
                      feedPath={feedPath}
                      selectable={selectable}
                      cellRef={cellRef}
                      useImageLoading={isNextFeedLoading}
                      uiComponent={uiComponent}
                    />
                  </div>
                </>
              );
            })
          )}

          <HorizontalListButton
            direction="right"
            showBtn={toggleButtons.enabled && toggleButtons.right}
            uiComponent={uiComponent}
            continaerRef={ref}
            cellRef={cellRef}
            screenPadding={screenPadding}
            setToggleButtons={setToggleButtons}
            isNextFeedLoading={isNextFeedLoading}
          />
        </section>
      </div>
    </div>
  );
}
//#endregion
