export function ChevronIcon({
  className,
  direction,
}: {
  className?: string;
  direction: 'left' | 'right';
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline
        points={direction === 'left' ? '15 18 9 12 15 6' : '9 18 15 12 9 6'}
      ></polyline>
    </svg>
  );
}
