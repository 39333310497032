import { useLoaderData } from '@remix-run/react';
import logger from '~/services/logger';
import {
  Cell1Skeleton,
  CellInfoSkeleton,
} from '~/components/cell-components/skeletons.tsx';
import Cell1 from '~/plugins/cell1/components/index.tsx';
import Cell2 from './cell2.tsx';
import Cell3 from './cell3.tsx';
import ResponsiveHeroCell from './responsive-hero-cell.tsx';
import CellInfo from './info-cell.tsx';
import type { FeedEntry, UIComponent } from '~/services/layout/index.server.ts';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { addParametersToURL } from '~/services/links/index.ts';
import { useTapCellAnalyticsData } from '~/hooks/use-tap-cell-analytics-data.ts';
import { useCWProgress } from '~/hooks/use-cw-progress.ts';

export type CellProps = {
  selectable?: boolean;
  cellId: string;
  entry: FeedEntry;
  cellConfig: any;
  isClient: boolean;
  progress: number;
  cellStyles?: any;
  linkTo?: string;
  linkToTrailer?: string;
  useImageLoading?: boolean;
  isCircularScrolling?: boolean;
  cellRef?: any;
  analyticsData: any;
};

type Props = {
  selectable?: boolean;
  cellId: string;
  entry?: FeedEntry;
  pluginIdentifier: string | undefined;
  feedPath?: string;
  isLoading?: boolean;
  isClient?: boolean;
  useImageLoading?: boolean;
  isCircularScrolling?: boolean;
  cellRef?: any;
  uiComponent: UIComponent;
};

export function Cell({
  selectable,
  cellId,
  entry,
  pluginIdentifier,
  isLoading,
  isClient,
  useImageLoading,
  isCircularScrolling = false,
  cellRef,
  uiComponent,
}: Props): JSX.Element {
  // TODO: create a more granular hook for getting only cellStyles
  const { cellStyles, debug, debugLayoutId } =
    useLoaderData<typeof rootLoader>();

  const tapCellAnalyticsData = useTapCellAnalyticsData({ uiComponent, entry });

  const progress = useCWProgress(entry);

  try {
    const cells: any = {
      cell_1_mobile: isLoading ? Cell1Skeleton : Cell1,
      cell_2_mobile: Cell2,
      cell_3_mobile: Cell3,
      // TODO: Remove cell_4
      cell_4: ResponsiveHeroCell,
      responsive_hero_cell: ResponsiveHeroCell,
      cell_info_mobile: isLoading ? CellInfoSkeleton : CellInfo,
    };

    const CurrentCell = pluginIdentifier
      ? cells[pluginIdentifier]
      : cells.cell_1_mobile;

    // use || {} to make sure we don't crash if no cellStyles are set
    const cellConfig: any = cellStyles[cellId] || {};

    if (!entry && !isLoading) return <></>;

    const linkTo = addParametersToURL(isLoading ? '' : entry?._webLink, {
      debug,
      'layout-id': debugLayoutId,
    });

    //  TODO: For now 'trailer' type is hardcoded, but it should be dynamic and controlled by the cell config
    const titleAlternateId = entry?.extensions?.trailerId;

    const linkToTrailer: string | undefined = isLoading
      ? ''
      : entry?._trailerLink;

    return (
      <CurrentCell
        selectable={selectable}
        cellId={cellId}
        entry={entry}
        isClient={isClient}
        cellConfig={cellConfig}
        cellStyles={cellStyles}
        progress={progress}
        linkTo={linkTo}
        linkToTrailer={titleAlternateId && linkToTrailer}
        useImageLoading={useImageLoading}
        isCircularScrolling={isCircularScrolling}
        cellRef={cellRef}
        analyticsData={tapCellAnalyticsData}
      />
    );
  } catch (error: any) {
    logger.info(`Cell: ${error.message}`);
    return <></>;
  }
}
