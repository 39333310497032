import { useCallback } from 'react';
import { track } from '~/services/analytics/index.client.ts';
import { type TapCellAnalyticsData } from './use-tap-cell-analytics-data';

export function useTrackAnalyticsData(analyticsdata: TapCellAnalyticsData) {
  return useCallback(() => {
    const { eventName, trackData } = analyticsdata || {};
    if (!eventName || !trackData) return;
    track(eventName, trackData);
  }, [analyticsdata]);
}
