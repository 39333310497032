import { isLastComponentInScreen } from '~/services/layout/layout';
import { useInView } from './use-is-in-view';
import { useLoaderData } from '@remix-run/react';
import type { loader as rootLoader } from '~/routes/_index.ts';

/**
 * Hook to determine if the component is in view and is the last
 * component on the screen and the client loaded feed is not loading.
 * @param ref - The component reference
 * @param isLoading - The client loaded feed loading state
 * @param componentType - The component type
 * @returns boolean to trigger the next feed pagination
 */
export function usePaginationCondition({
  ref,
  isLoading,
  componentType,
}: {
  ref: any;
  isLoading: boolean | undefined;
  componentType: string;
}): boolean {
  const { screen } = useLoaderData<typeof rootLoader>();

  const isInView: boolean = useInView({
    ref,
  });

  const isLastComponentOnScreen: boolean = isLastComponentInScreen(
    screen?.ui_components,
    componentType
  );

  return isInView && isLastComponentOnScreen && !isLoading;
}
