import { Form } from '@remix-run/react';
import { useIsLoggedIn } from '~/hooks/use-is-logged-in';

import logger from '~/services/logger';

// quick-brick-user-account-ui-component
export default function UserAccount({ uiComponent }: any): JSX.Element {
  const { isLoggedIn } = useIsLoggedIn();

  try {
    const ZAPP_LOCALIZATIONS: string = process?.env?.ZAPP_LOCALIZATIONS || 'en';

    const componentLocalizations =
      uiComponent?.localizations?.[ZAPP_LOCALIZATIONS];

    const loginBtnText: string =
      componentLocalizations?.login_button_1_title_text || 'Log In';

    const logoutBtnText: string =
      componentLocalizations?.logout_title_text || 'Log Out';

    const btnText = isLoggedIn ? logoutBtnText : loginBtnText;

    const onLoginClick = (e: any) => {
      if (isLoggedIn) return;

      e.preventDefault();
      window.location.pathname = '/login';
    };

    return (
      <>
        <section
          className={`user-account-${uiComponent.id} flex justify-center`}
        >
          <Form method="post" action="/api/logout">
            <button
              aria-label={`login-logout-button`}
              onClick={(e) => onLoginClick(e)}
              className="mb-logout-btn-b w-logout-btn rounded-logout-btn-radius border-logout-btn-width border-logout-btn-color bg-logout-btn font-logout-btn-font-family text-logout-btn-font-size text-logout-btn-color logout-btn-focus-color focus:logout-btn-bg-focus-color focus:logout-btn-focus-color focus:logout-btn-border-focus-color"
            >
              {btnText}
            </button>
          </Form>
        </section>
      </>
    );
  } catch (error: any) {
    logger.info(`UserAccount: ${error.message}`);
    return <></>;
  }
}
