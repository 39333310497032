const manifest = {
  plugin_id: 1188,
  identifier: 'hero-qb',
  manifest_version: '1.0.0-alpha.2',
  dependency_name: '',
  name: 'Hero (QB)',
  description:
    'You’ll use the `Hero` component in a large variety of screens, listing items of any type.All cells are displayed vertically on top of one another.',
  type: 'ui_component',
  general: {
    fields: [],
  },
  styles: {
    fields: [
      {
        group: true,
        label: 'Component configuration',
        folded: false,
        fields: [
          {
            key: 'component_margin_top',
            label: 'Component Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_margin_bottom',
            label: 'Component Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_padding_top',
            label: 'Component Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_padding_bottom',
            label: 'Component Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_padding_left',
            label: 'Component Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_padding_right',
            label: 'Component Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_background_color',
            initial_value: '#FFFFFF00',
            label: 'Component Background Color',
            type: 'color_picker_rgba',
          },
          {
            key: 'hero_carousel_switch',
            label: 'Enable Carousel',
            initial_value: true,
            label_tooltip:
              'Enable or disable hero carousel.\n      If the number of feed items is 1, or if the "item limit" field is set to 1, \n      the carousel will not be displayed.\n      The maximum number of items in the carousel is 6.\n      You may want to set "Number of lines" for the text labels in your selected cell style.\n      ',
            type: 'switch',
          },
          {
            key: 'hero_carousel_autoplay_switch',
            label: 'Enable Carousel Autoplay',
            initial_value: true,
            label_tooltip: 'Enable autoplay for carousel, default: true',
            type: 'switch',
          },
          {
            key: 'hero_carousel_autoplay_speed',
            label: 'Carousel Autoplay Speed In Seconds',
            initial_value: 6,
            label_tooltip:
              'The amount of time in seconds between each autoplay transition. Default: 6',
            type: 'number_input',
          },
          // {
          //   key: 'hero_carousel_autoplay_pause_on_hover',
          //   label: 'Pause Autoplay On Hover',
          //   initial_value: false,
          //   label_tooltip:
          //     'Pause autoplay when hovering over carousel, default: false',
          //   type: 'switch',
          // },
          {
            key: 'hero_carousel_transition_duration',
            label: 'Carousel Transition Duration',
            initial_value: 700,
            label_tooltip:
              'The amount of time in miliseconds or seconds for the transition between slides. Default: 0.7 or 700',
            type: 'number_input',
          },
          {
            key: 'hero_carousel_right_button_icon',
            label: 'Right carousel button icon',
            initial_value: '',
            type: 'uploader',
          },
          {
            key: 'hero_carousel_left_button_icon',
            label: 'Left carousel button icon',
            initial_value: '',
            type: 'uploader',
          },
          {
            key: 'hero_carousel_indicator_color',
            label: 'Carousel indicator color',
            initial_value: '#9CA3AF',
            type: 'color_picker_rgba',
          },
          {
            key: 'hero_carousel_indicator_active_color',
            label: 'Carousel indicator active color',
            initial_value: '#3B82F6',
            type: 'color_picker_rgba',
          },
        ],
      },
    ],
  },
  rules: {
    fields: [
      {
        key: 'item_limit',
        label: 'Item limit',
        type: 'number_input',
      },
      {
        key: 'component_cells_selectable',
        initial_value: true,
        label: 'Cells selectable',
        type: 'switch',
      },
      {
        key: 'load_feed_from_client',
        label: 'Load feed from the client',
        label_tooltip:
          'If enabled, the feed will be loaded from the browser (can be used to load user specific data such as favorites or continue watching). By default all feeds are loaded from the server.',
        initial_value: false,
        type: 'switch',
      },
    ],
  },
  data: {
    fields: [
      {
        key: 'source',
        label: 'Source',
        type: 'text_input',
      },
    ],
  },
  characteristics: {
    group: false,
    appearance: ['group', 'screen'],
  },
} as const;

export default manifest;
