import { type Theme } from 'planby-mit';

export const epgTheme = (styles: any): Theme => {
  const isScrollbar: boolean = !!styles?.epg_scrollbar_switch;

  return {
    primary: {
      600: styles?.epg_item_background_color || '#1a202c', // program background color
      900: styles?.channel_item_background_color || '#171923', // channels background color
    },
    grey: { 300: styles?.epg_item_label_1_font_color || '#d1d1d1' }, // program text color
    white: '#fff',
    green: {
      300: styles?.timeline_vertical_line_color || '#2C7A7B', // timeline color
    },
    loader: {
      teal: '#5DDADB',
      purple: '#3437A2',
      pink: '#F78EB6',
      bg: '#171923db', // loader background color
    },
    scrollbar: {
      border: '#ffffff',
      // @ts-ignore
      thumb: isScrollbar
        ? {
            bg: styles?.epg_scrollbar_color || '#e1e1e1',
          }
        : {},
    },
    gradient: {
      // live program gradient background
      blue: {
        300: styles?.epg_live_item_gradient_right_color || '#002eb3', // right
        600: styles?.epg_live_item_gradient_center_color || '#002360', // center
        900: styles?.epg_live_item_gradient_left_color || '#051937', // left
      },
    },
    text: {
      grey: {
        300: '#9E9E9E',
        500: '#718096',
      },
    },
    timeline: {
      divider: {
        bg: '#9E9E9E',
      },
    },
  };
};
