import logger from '~/services/logger';
import cell1Manifest from '~/services/plugins/cell_1_v2/config/manifest.ts';
import cell2Manifest from '~/services/plugins/cell_2_v2/config/manifest.ts';
import cell3Manifest from '~/services/plugins/cell_3_v2/config/manifest.ts';
import cellInfoMobileManifest from '~/services/plugins/cell_info_mobile/config/manifest.ts';
import {
  defaultMobileCell1Id,
  defaultMobileCellInfoId,
} from '~/services/layout/layout';
import { getPluginConfig } from '~/utils/get-plugin-configuration';

export type CellPluginIdentifier =
  | 'cell_1_v2'
  | 'cell_2_v2'
  | 'cell_3_v2'
  | 'cell_info_mobile';

/**
 * Get the cell configurations for the responsive breakpoints w/ initial values from
 * each cell type manifest as needed.
 * @param cellConfig - the cell configuration object for the responsive breakpoint
 * @param cellPluginIdentifier - the plugin identifier for the cell
 * @returns object with styles and assets
 */
export function getCellConfig(
  cellConfig: any,
  cellPluginIdentifier: CellPluginIdentifier
): {
  styles: any;
  assets: any;
} {
  try {
    if (cellPluginIdentifier === 'cell_1_v2')
      return getPluginConfig<typeof cell1Manifest>(
        cell1Manifest,
        cellConfig?.configuration
      );

    if (cellPluginIdentifier === 'cell_2_v2')
      return getPluginConfig<typeof cell2Manifest>(
        cell2Manifest,
        cellConfig?.configuration
      );

    if (cellPluginIdentifier === 'cell_3_v2')
      return getPluginConfig<typeof cell3Manifest>(
        cell3Manifest,
        cellConfig?.configuration
      );

    if (cellPluginIdentifier === 'cell_info_mobile')
      return getPluginConfig<typeof cellInfoMobileManifest>(
        cellInfoMobileManifest,
        cellConfig?.configuration
      );

    return { styles: {}, assets: {} };
  } catch (error) {
    throw new Error(
      `The responsive cell ID from the component does not exist in the cellStyles object.`
    );
  }
}

/**
 * Get default mobile cell_1_v2 and cell_info_mobile initial values configurations.
 */
export function getDefaultMobileCellsConfigs() {
  try {
    const defaultMobileCell1Config = getPluginConfig<typeof cell1Manifest>(
      cell1Manifest,
      {}
    );

    const defaultMobileCellInfoConfig = getPluginConfig<
      typeof cellInfoMobileManifest
    >(cellInfoMobileManifest, {});

    const defaultMobileCellsStyles = {
      [defaultMobileCell1Id]: {
        plugin_identifier: 'cell_1_v2',
        configuration: {
          ...defaultMobileCell1Config,
        },
      },
      [defaultMobileCellInfoId]: {
        plugin_identifier: 'cell_info_mobile',
        configuration: {
          ...defaultMobileCellInfoConfig,
        },
      },
    };

    return defaultMobileCellsStyles;
  } catch (error: any) {
    logger.info(`getDefaultMobileCellsToCellStyles: ${error.message}`);
  }
}
