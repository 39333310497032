export const setCSSVariables = (config: any) => {
  const root = document.documentElement;

  const properties = {
    '--payment_info_general_font_color':
      config?.payment_info_general_font_color,
    '--payment_info_general_font_size': `${config?.payment_info_general_font_size}px`,
    '--payment_info_general_font_family':
      config?.payment_info_general_font_family,
    '--payment_info_general_bg_color': config?.payment_info_general_bg_color,
    '--payment_info_title_font_color': config?.payment_info_title_font_color,
    '--payment_info_title_font_size': `${config?.payment_info_title_font_size}px`,
    '--payment_info_title_font_family': config?.payment_info_title_font_family,
    '--cleeng_show_transactions': config?.cleeng_show_transactions
      ? 'block'
      : 'none',
    '--payment_info_card_title_font_color':
      config?.payment_info_card_title_font_color,
    '--payment_info_card_title_font_size': `${config?.payment_info_card_title_font_size}px`,
    '--payment_info_card_title_font_family':
      config?.payment_info_card_title_font_family,
    '--payment_info_card_subtitle_font_color':
      config?.payment_info_card_subtitle_font_color,
    '--payment_info_card_subtitle_font_size': `${config?.payment_info_card_subtitle_font_size}px`,
    '--payment_info_card_subtitle_font_family':
      config?.payment_info_card_subtitle_font_family,
    '--payment_info_method_font_color': config?.payment_info_method_font_color,
    '--payment_info_method_font_size': `${config?.payment_info_method_font_size}px`,
    '--payment_info_method_font_family':
      config?.payment_info_method_font_family,
    '--payment_info_details_title_font_color':
      config?.payment_info_details_title_font_color,
    '--payment_info_details_title_font_size': `${config?.payment_info_details_title_font_size}px`,
    '--payment_info_details_title_font_family':
      config?.payment_info_details_title_font_family,
    '--payment_info_details_subtitle_font_color':
      config?.payment_info_details_subtitle_font_color,
    '--payment_info_details_subtitle_font_size': `${config?.payment_info_details_subtitle_font_size}px`,
    '--payment_info_details_subtitle_font_family':
      config?.payment_info_details_subtitle_font_family,
    '--payment_info_back_btn_font_color':
      config?.payment_info_back_btn_font_color,
    '--payment_info_back_btn_font_size': `${config?.payment_info_back_btn_font_size}px`,
    '--payment_info_back_btn_font_family':
      config?.payment_info_back_btn_font_family,
    '--payment_info_back_btn_bg_color': config?.payment_info_back_btn_bg_color,
    '--payment_info_general_add_button_bg_color':
      config?.payment_info_general_add_button_bg_color,
    '--payment_info_wrapper_padding_top': `${config?.payment_info_wrapper_padding_top}px`,
    '--payment_info_wrapper_padding_bottom': `${config?.payment_info_wrapper_padding_bottom}px`,
    '--payment_info_wrapper_padding_left': `${config?.payment_info_wrapper_padding_left}px`,
    '--payment_info_wrapper_padding_right': `${config?.payment_info_wrapper_padding_right}px`,
  };

  Object.keys(properties).forEach((key) => {
    // @ts-ignore
    root.style.setProperty(key, properties[key]);
  });
};
