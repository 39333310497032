import logger from '~/services/logger';
import {
  getClientWidth,
  getIsFirstCell,
  getIsLastCell,
  getTotalScrollWidth,
  getVisibleCells,
  getWidth,
} from './getters';
import { getCSSNumber } from '~/services/layout/layout';

/**
 * Triggers the scroll action based on the direction and the circularCondition flag
 * @param ref - The horizontal list container ref, this is the most parent container with a ref attached to it
 * @param cellRef - The cell ref
 * @param gutter - The component horizontal gutter
 * @param feedEntriesLength - The feed entries length
 * @param circularCondition - The circular condition that is true when circular scoll is enabled OR autoscroll is enabled with loop
 * @param scrollRight - The scroll right function
 * @param scrollLeft - The scroll left function
 * @param direction - The direction of the scroll action
 */
export function triggerScrollAction({
  ref,
  cellRef,
  gutter,
  feedEntriesLength,
  circularCondition,
  scrollRight,
  scrollLeft,
  direction,
  isInitialLoad,
}: {
  ref: any;
  cellRef: any;
  gutter: number | undefined;
  feedEntriesLength: number;
  circularCondition: boolean;
  scrollRight: Function;
  scrollLeft: Function;
  direction: 'left' | 'right';
  isInitialLoad?: boolean;
}) {
  if (direction !== 'left' && direction !== 'right') {
    logger.info(`Error: Invalid direction: ${direction}`);
    return;
  }

  if (!circularCondition) {
    if (direction === 'right') scrollRight();
    if (direction === 'left') scrollLeft();
    return;
  }

  if (!gutter) gutter = 0;

  const isLastCell: boolean = getIsLastCell({
    ref,
    cellRef,
    feedEntriesLength,
    gutter,
  });

  if (!isLastCell && direction === 'right') {
    scrollRight();
    return;
  }

  const isFirstCell: boolean = getIsFirstCell({
    ref,
    cellRef,
    gutter,
  });

  if (!isFirstCell && direction === 'left') {
    scrollLeft();
    return;
  }

  const left: number | undefined = getCircularScrollXPixels({
    ref,
    cellRef,
    gutter,
    direction,
  });

  if (!left) {
    logger.info(`Error: ${direction} circular scroll action failed`);
    return;
  }

  ref.current?.scrollTo({
    left,
    behavior: 'instant',
  });

  if (isInitialLoad) return;

  if (direction === 'right') scrollRight();
  if (direction === 'left') scrollLeft();
}

/**
 * Get Circular Scroll X Pixels
 * @param ref - The horizontal list container ref, this is the most parent container with a ref attached to it
 * @param cellRef - The cell ref
 * @param gutter - The component horizontal gutter which is applied as margin-right to the cell child container inside the the horizontal list
 * @param direction - The direction of the circular scroll action
 * @returns The number of pixels along the X axis to scroll the horizontal list container to
 */
function getCircularScrollXPixels({
  ref,
  cellRef,
  gutter,
  direction,
}: {
  ref: any;
  cellRef: any;
  gutter: number;
  direction: 'left' | 'right';
}) {
  const totalScrollWidth: number = getTotalScrollWidth({ ref });

  const cellWidth: number = getWidth({
    ref: cellRef,
    gutter,
  });

  let left: number = 0;

  const visibleCells: number =
    getVisibleCells({
      ref,
      cellRef,
      gutter,
    }) || 1;

  let numberOfPxToScroll: number =
    totalScrollWidth / 2 - cellWidth * visibleCells;

  if (direction === 'right') {
    const componentPaddingRight = getCSSNumber(ref.current, 'padding-right');

    let partiallyVisibleCellOnTheLeft = 0;

    const clientWidth: number = getClientWidth({
      ref,
    });

    if (clientWidth > cellWidth * visibleCells)
      partiallyVisibleCellOnTheLeft = clientWidth - cellWidth * visibleCells;

    numberOfPxToScroll =
      numberOfPxToScroll +
      componentPaddingRight -
      partiallyVisibleCellOnTheLeft;
  }

  if (direction === 'right') left = numberOfPxToScroll;
  if (direction === 'left') left = numberOfPxToScroll + cellWidth;

  if (visibleCells > 1 && direction === 'left') {
    const scrollOffset: number = visibleCells > 2 ? visibleCells - 1 : 1;

    const componentPaddingLeft = getCSSNumber(ref.current, 'padding-left');

    left = left + cellWidth * scrollOffset + gutter / 2 - componentPaddingLeft;
  }

  if (!left) {
    logger.info(`Error: ${direction} circular scroll action failed`);
    return;
  }

  return left;
}
