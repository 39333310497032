import { useEffect, useState } from 'react';

// Returns true when the document is ready
// https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
export function useReadyState(): boolean {
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  useEffect(() => {
    const handleReadyStateChange = () => {
      if (document.readyState === 'complete') setIsDocumentReady(true);
    };

    if (!isDocumentReady) handleReadyStateChange();
  }, [isDocumentReady]);

  return isDocumentReady;
}
