import { useEffect, useState } from 'react';

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const update = () => {
    setIsOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', update);
    window.addEventListener('online', update);

    return () => {
      window.removeEventListener('offline', update);
      window.removeEventListener('online', update);
    };
  });

  return isOnline;
};

export default useIsOnline;
