import { type FeedEntry } from '~/services/layout/index.ts';
import { useLoaderData } from '@remix-run/react';
import { loader } from '~/routes/_index.tsx';
import { useGetStorageFeed } from './use-feed';

/**
 * Get the progress of a continue watching entry
 * @param entry - The entry to get the progress from
 * @returns - The progress from the feed entry or the continue watching entry
 */
export function useCWProgress(
  entry: FeedEntry | undefined
): number | undefined {
  const { isContinueWatchingSyncedFeed } = useLoaderData<typeof loader>();

  const { feed } = useGetStorageFeed({
    storageKey: 'continue-watching',
    condition: isContinueWatchingSyncedFeed,
  });

  const continueWatchingEntry = feed?.entry?.find(
    (e: any) => e.id === entry?.id
  );

  return (
    entry?.extensions?.progress || continueWatchingEntry?.extensions?.progress
  );
}
