import React, { Suspense, lazy } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import { type AuthData } from '~/services/plugins/payment-info/components/index.tsx';

const PlanDetails = lazy(() =>
  // @ts-ignore
  import('@cleeng/mediastore-sdk').then((module) => ({
    default: module.PlanDetails,
  }))
);
const Provider = lazy(() =>
  import('react-redux').then((module) => ({ default: module.Provider }))
);

interface CleengProviderProps {
  config: any;
  authData: AuthData;
}

const getCleengConfig = async () => {
  try {
    // @ts-ignore
    const module = await import('@cleeng/mediastore-sdk');
    const Config = module.Config;
    const Store = module.store;
    return { Config, Store };
  } catch (err) {
    console.error('Failed to load Config', err);
    throw err;
  }
};

export default function CleengProvider({
  config,
  authData,
}: CleengProviderProps) {
  const [loading, setLoading] = React.useState(true);

  const storeRef = React.useRef(null);

  const {
    cleeng_publisher_id,
    cleeng_environment,
    cleeng_skip_available_downgrades_step: skipAvailableDowngradesStep,
    cleeng_skip_available_free_extension_step: skipAvailableFreeExtensionStep,
    cleeng_display_grace_period_error: displayGracePeriodError,
  } = config?.general;

  const {
    cleeng_general_font_color,
    cleeng_success_color,
    cleeng_primary_color,
    cleeng_loader_color,
    cleeng_error_color,
  } = config?.styles;

  const initializeCleeng = React.useCallback(async () => {
    const { Config, Store } = await getCleengConfig();

    storeRef.current = Store;

    if (authData && Config && cleeng_publisher_id && cleeng_environment) {
      Config.setEnvironment(cleeng_environment);
      Config.setPublisher(cleeng_publisher_id);
      Config.setJWT(authData?.access_token);
      Config.setRefreshToken(authData?.refresh_token);
      Config.setTheme({
        fontColor: cleeng_general_font_color,
        successColor: cleeng_success_color,
        primaryColor: cleeng_primary_color,
        loaderColor: cleeng_loader_color,
        errorColor: cleeng_error_color,
      });
    }

    setLoading(false);
  }, [getCleengConfig]);

  React.useEffect(() => {
    initializeCleeng();
  }, []);

  return (
    <ClientOnly fallback={<></>}>
      {() => {
        if (loading) {
          return null;
        }

        return (
          <Suspense fallback={<></>}>
            <Provider
              // @ts-ignore
              store={storeRef.current}
            >
              <Suspense fallback={<></>}>
                <div className="relative z-0">
                  <PlanDetails
                    // @ts-ignore
                    skipAvailableDowngradesStep={skipAvailableDowngradesStep}
                    skipAvailableFreeExtensionStep={
                      skipAvailableFreeExtensionStep
                    }
                    displayGracePeriodError={displayGracePeriodError}
                  />
                </div>
              </Suspense>
            </Provider>
          </Suspense>
        );
      }}
    </ClientOnly>
  );
}
