import { getDuration } from '~/services/layout/layout';
import type { FeedEntry } from '~/services/layout/index.server.ts';
import {
  getCellBreakpointValues,
  getCellVisibillityClasses,
} from './power-cell-getters';

export function RuntimeV2({
  entry,
  styles,
}: {
  entry: FeedEntry;
  styles: any;
}): JSX.Element {
  const duration: string | undefined = getDuration(entry);

  if (!duration) return <></>;

  return (
    <div
      className={getCellVisibillityClasses(
        getCellBreakpointValues({
          config: styles,
          key: 'runtime_switch',
        }),
        'absolute bottom-0 right-0 z-[3]'
      )}
    >
      <div className="mb-runtime-b ml-runtime-l mr-runtime-r mt-runtime-t rounded-runtime-radius bg-runtime pb-runtime-b pl-runtime-l pr-runtime-r pt-runtime-t">
        <p className="font-runtime-font-family text-runtime-font-size text-runtime-color">
          <span className="leading-runtime-line-height">{duration}</span>
        </p>
      </div>
    </div>
  );
}
