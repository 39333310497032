import { twMerge } from 'tailwind-merge';
import {
  getCellBreakpointValues,
  type AlignmentResponsive,
  type ResponsiveBooleans,
  type ResponsiveSources,
} from './power-cell-getters';
import { type UseResponsiveBreakpoint } from '~/hooks/use-responsive-breakpoint';
import { getJustifyContentClassName } from '~/utils/styles';

export type ButtonNumber = 1 | 2 | 3;
export type ButtonName = 'button1' | 'button2' | 'button3';
export type ButtonAction = 'play' | 'trailer' | 'favorite';

/**
 * Get the buttons container visibillity for each breakpoint
 * @param assets - cell assets for each breakpoint
 * @param textLabelKeyName - text label key name
 * @returns - buttons container visibillity for each breakpoint
 */
export function getButtonsContainerVisibillity({
  assets,
  textLabelKeyName,
}: {
  assets: any;
  textLabelKeyName: string;
}): ResponsiveBooleans {
  const abovePos: string = `above_${textLabelKeyName}`;
  const belowPos: string = `below_${textLabelKeyName}`;

  const mobilePos: string = assets.mobile?.buttons_container_position;
  const tabletPos: string = assets.tablet?.buttons_container_position;
  const desktopPos: string = assets.desktop?.buttons_container_position;
  const largeDesktopPos: string =
    assets['large-desktop']?.buttons_container_position;

  const isMobileVisibile: boolean =
    mobilePos === belowPos || mobilePos === abovePos;
  const isTabletVisibile: boolean =
    tabletPos === belowPos || tabletPos === abovePos;
  const isDesktopVisibile: boolean =
    desktopPos === belowPos || desktopPos === abovePos;
  const isLargeDesktopVisibile: boolean =
    largeDesktopPos === belowPos || largeDesktopPos === abovePos;

  const mobileButtonsContainerSwitch: boolean =
    assets.mobile?.buttons_container_switch;

  const tabletButtonsContainerSwitch: boolean =
    assets.tablet?.buttons_container_switch;
  const desktopButtonsContainerSwitch: boolean =
    assets.desktop?.buttons_container_switch;
  const largeDesktopButtonsContainerSwitch: boolean =
    assets['large-desktop']?.buttons_container_switch;

  return {
    mobile: mobileButtonsContainerSwitch && isMobileVisibile,
    tablet: tabletButtonsContainerSwitch && isTabletVisibile,
    desktop: desktopButtonsContainerSwitch && isDesktopVisibile,
    ['large-desktop']:
      largeDesktopButtonsContainerSwitch && isLargeDesktopVisibile,
  };
}

/**
 * Get the buttons container position classNames for each breakpoint
 * @param assets - cell assets for each breakpoint
 * @param textLabelKeyName - text label key name
 * @returns - buttons container position classNames for each breakpoint
 */
export function getButtonsContainerPositionClasses(
  assets: any,
  textLabelKeyName: string
): string {
  const abovePos: string = `above_${textLabelKeyName}`;

  const mobilePos: string = assets.mobile?.buttons_container_position;
  const tabletPos: string = assets.tablet?.buttons_container_position;
  const desktopPos: string = assets.desktop?.buttons_container_position;
  const largeDesktopPos: string =
    assets['large-desktop']?.buttons_container_position;

  const isMobilePosAbove: boolean = mobilePos === abovePos;
  const isTabletPosAbove: boolean = tabletPos === abovePos;
  const isDesktopPosAbove: boolean = desktopPos === abovePos;
  const isLargeDesktopPosAbove: boolean = largeDesktopPos === abovePos;

  return twMerge(
    'flex',
    isMobilePosAbove
      ? 'mobile:max-tablet:flex-col-reverse'
      : 'mobile:max-tablet:flex-col',
    isTabletPosAbove
      ? 'tablet:max-desktop:flex-col-reverse'
      : 'tablet:max-desktop:flex-col',
    isDesktopPosAbove
      ? 'desktop:max-large-desktop:flex-col-reverse'
      : 'desktop:max-large-desktop:flex-col',
    isLargeDesktopPosAbove
      ? 'large-desktop:flex-col-reverse'
      : 'large-desktop:flex-col'
  );
}

type AssetAlignment = 'left' | 'right';
export type AssetAlignmentResponsive = {
  mobile: AssetAlignment;
  tablet: AssetAlignment;
  desktop: AssetAlignment;
  'large-desktop': AssetAlignment;
};

export function getButtonsContainerAssetAlignmentClasses(
  assetAlignment: AssetAlignmentResponsive
) {
  return twMerge(
    'flex items-center',
    assetAlignment.mobile === 'left'
      ? 'mobile:max-tablet:flex-row-reverse'
      : 'mobile:max-tablet:flex-row',
    assetAlignment.tablet === 'left'
      ? 'tablet:max-desktop:flex-row-reverse'
      : 'tablet:max-desktop:flex-row',
    assetAlignment.desktop === 'left'
      ? 'desktop:max-large-desktop:flex-row-reverse'
      : 'desktop:max-large-desktop:flex-row',
    assetAlignment['large-desktop'] === 'left'
      ? 'large-desktop:flex-row-reverse'
      : 'large-desktop:flex-row'
  );
}

/**
 * On button mouse enter event handler to change the cursor, navigate to the button link (play or trailer) and handle the parent link
 * @param event - The mouse enter event object
 * @param buttonLink - The button link to navigate to (play or trailer)
 * @param isNonDirectLinkAction - Is the button link a non direct link action (favorite or trailer)
 */
export function onButtonMouseEnter({
  event,
  buttonLink,
  isNonDirectLinkAction,
}: {
  event: any;
  buttonLink: string;
  isNonDirectLinkAction: boolean;
}): void {
  const target = event.target as HTMLElement;
  target.style.cursor = 'pointer';
  const link = target.closest('a');
  if (!link) return;
  link.removeAttribute('href');
  if (isNonDirectLinkAction) return;
  link.setAttribute('href', buttonLink);
}

/**
 * On button mouse leave event handler to handle the cell parent link
 * @param event - The mouse leave event object
 * @param parentLink - The cell parent link
 */
export function onButtonMouseLeave({
  event,
  parentLink,
}: {
  event: any;
  parentLink: string;
}): void {
  const target = event.target as HTMLElement;
  const link = target.closest('a');
  if (link) link.setAttribute('href', parentLink);
}

type ResponsiveButtonAction = {
  mobile: ButtonAction;
  tablet: ButtonAction;
  desktop: ButtonAction;
  ['large-desktop']: ButtonAction;
};
export type ButtonContainerButtonAction = ButtonAction | 'unset';
export type ButtonsContainerButtonAction = {
  button1: ButtonContainerButtonAction;
  button2: ButtonContainerButtonAction;
  button3: ButtonContainerButtonAction;
};
/**
 * Get the buttons container button action link and action type for each button
 * @param assets - cell assets for each breakpoint
 * @param responsiveBreakpoint - responsive breakpoint hook
 * @returns - The action type for each button
 */
export function getButtonsContainerButtonAction({
  assets,
  responsiveBreakpoint,
}: {
  assets: any;
  responsiveBreakpoint: UseResponsiveBreakpoint;
}): ButtonsContainerButtonAction {
  const buttonsContainerButtonAction: ButtonsContainerButtonAction = {
    button1: 'unset',
    button2: 'unset',
    button3: 'unset',
  };

  const isResponsiveBreakpointUnset = responsiveBreakpoint === 'unset';

  [1, 2, 3].forEach((buttonNumber: number) => {
    const buttonAssignActions = getCellBreakpointValues({
      config: assets,
      key: `button${buttonNumber}_assign_action`,
    }) as ResponsiveButtonAction;

    const action: ButtonAction | 'unset' = isResponsiveBreakpointUnset
      ? 'unset'
      : buttonAssignActions[responsiveBreakpoint];

    buttonsContainerButtonAction[`button${buttonNumber}` as ButtonName] =
      action;
  });

  return buttonsContainerButtonAction;
}

type ActionPlugin = {
  localizations: { label_1: string };
  customConfiguration: {
    default_asset: string;
    hover_asset: string;
  };
};
export type FavoriteAction = {
  localizations: { default_label: string; active_label: string };
  customConfiguration: {
    mobile_flavour_1_default_asset: string;
    mobile_flavour_1_active_asset: string;
  };
};

export type ButtonContainerButtonData = {
  labels: ResponsiveSources;
  assets: {
    default: ResponsiveSources;
    hover: ResponsiveSources;
  };
};
export type ButtonsContainerButtonData = {
  [x: string]: ButtonContainerButtonData;
};

export type GetButtonsContainerButtonDataArgs = {
  assets: any;
  navigationAction: ActionPlugin;
  trailerAction: ActionPlugin;
  favoritesAction: FavoriteAction;
  favorite: boolean;
};
/**
 * Get the buttons container button data for each button
 * @param buttonNumber - button number (1, 2 or 3)
 * @param assets - cell assets for each breakpoint
 * @param navigationAction - navigation action plugin
 * @param trailerAction - trailer action plugin
 * @param favoritesAction - favorites action plugin
 * @param favorite - is the cell favorite or not
 * @returns - The labels and assets for each button
 */
export function getButtonsContainerButtonData({
  assets,
  navigationAction,
  trailerAction,
  favoritesAction,
  favorite,
}: GetButtonsContainerButtonDataArgs): ButtonsContainerButtonData {
  const playActionLabel1 = navigationAction?.localizations?.label_1 || '';
  const playActionAsset =
    navigationAction?.customConfiguration?.default_asset || '';
  const playActionHoverAsset =
    navigationAction?.customConfiguration?.hover_asset || '';

  const trailerActionLabel1 = trailerAction?.localizations?.label_1 || '';
  const trailerActionAsset =
    trailerAction?.customConfiguration?.default_asset || '';
  const trailerActionHoverAsset =
    trailerAction?.customConfiguration?.hover_asset || '';

  const favoriteActionDefaultLabel1 =
    favoritesAction?.localizations?.default_label || '';
  const favoriteActionActiveLabel1 =
    favoritesAction?.localizations?.active_label || '';

  const actionLabels = {
    play: playActionLabel1,
    trailer: trailerActionLabel1,
    favorite: favorite
      ? favoriteActionActiveLabel1
      : favoriteActionDefaultLabel1,
  };

  const actionDefaultAssets = {
    play: playActionAsset,
    trailer: trailerActionAsset,
  };

  const actionHoverAssets = {
    play: playActionHoverAsset,
    trailer: trailerActionHoverAsset,
  };

  const buttonsContainerButtonData: ButtonsContainerButtonData = {};

  [1, 2, 3].forEach((buttonNumber: number) => {
    const buttonAssignActions = getCellBreakpointValues({
      config: assets,
      key: `button${buttonNumber}_assign_action`,
    }) as ResponsiveButtonAction;

    const buttonMobileAction: ButtonAction = buttonAssignActions.mobile;
    const buttonTabletAction: ButtonAction = buttonAssignActions.tablet;
    const buttonDesktopAction: ButtonAction = buttonAssignActions.desktop;
    const buttonLargeDesktopAction: ButtonAction =
      buttonAssignActions['large-desktop'];

    const getButtonLabel: Function = (action: ButtonAction): string =>
      actionLabels[action];

    const buttonMobileLabel1: string = getButtonLabel(buttonMobileAction);
    const buttonTabletLabel1: string = getButtonLabel(buttonTabletAction);
    const buttonDesktopLabel1: string = getButtonLabel(buttonDesktopAction);
    const buttonLargeDesktopLabel1: string = getButtonLabel(
      buttonLargeDesktopAction
    );

    const getButtonDefaultAsset: Function = (
      action: Exclude<ButtonAction, 'favorite'>
    ): string => actionDefaultAssets[action];

    const buttonMobileAsset: string = getButtonDefaultAsset(buttonMobileAction);
    const buttonTabletAsset: string = getButtonDefaultAsset(buttonTabletAction);
    const buttonDesktopAsset: string =
      getButtonDefaultAsset(buttonDesktopAction);
    const buttonLargeDesktopAsset: string = getButtonDefaultAsset(
      buttonLargeDesktopAction
    );

    const getButtonHoverAsset: Function = (
      action: Exclude<ButtonAction, 'favorite'>
    ): string => actionHoverAssets[action];

    const buttonMobileHoverAsset: string =
      getButtonHoverAsset(buttonMobileAction);
    const buttonTabletHoverAsset: string =
      getButtonHoverAsset(buttonTabletAction);
    const buttonDesktopHoverAsset: string =
      getButtonHoverAsset(buttonDesktopAction);
    const buttonLargeDesktopHoverAsset: string = getButtonHoverAsset(
      buttonLargeDesktopAction
    );

    buttonsContainerButtonData[`button${buttonNumber}`] = {
      labels: {
        mobile: buttonMobileLabel1,
        tablet: buttonTabletLabel1,
        desktop: buttonDesktopLabel1,
        'large-desktop': buttonLargeDesktopLabel1,
      },
      assets: {
        default: {
          mobile: buttonMobileAsset,
          tablet: buttonTabletAsset,
          desktop: buttonDesktopAsset,
          'large-desktop': buttonLargeDesktopAsset,
        },
        hover: {
          mobile: buttonMobileHoverAsset,
          tablet: buttonTabletHoverAsset,
          desktop: buttonDesktopHoverAsset,
          'large-desktop': buttonLargeDesktopHoverAsset,
        },
      },
    };
  });

  return buttonsContainerButtonData;
}

/**
 * Get the buttons container stacking classNames for each breakpoint
 * @param assets - cell assets for each breakpoint
 * @returns - buttons container stacking classNames for each breakpoint
 */
export function getButtonsContainerStackingClasses(assets: any) {
  const buttonsContainerStacking: any = getCellBreakpointValues({
    config: assets,
    key: 'buttons_container_stacking',
  }) as AlignmentResponsive;

  return twMerge(
    'flex',
    buttonsContainerStacking.mobile === 'vertical' &&
      'mobile:max-tablet:flex-col',
    buttonsContainerStacking.tablet === 'vertical' &&
      'tablet:max-desktop:flex-col',
    buttonsContainerStacking.desktop === 'vertical' &&
      'desktop:max-large-desktop:flex-col',
    buttonsContainerStacking['large-desktop'] === 'vertical' &&
      'large-desktop:flex-col'
  );
}

export function getButtonContainerAlignmentClasses(assets: any): string {
  const buttonsContainerAlign: any = getCellBreakpointValues({
    config: assets,
    key: 'buttons_container_align',
  });
  const buttonsContainerStacking: any = getCellBreakpointValues({
    config: assets,
    key: 'buttons_container_stacking',
  }) as AlignmentResponsive;

  const {
    mobile: mobileAlign,
    tablet: tabletAlign,
    desktop: desktopAlign,
    'large-desktop': largeDesktopAlign,
  } = buttonsContainerAlign;

  const {
    mobile: mobileStacking,
    tablet: tabletStacking,
    desktop: desktopStacking,
    'large-desktop': largeDesktopStacking,
  } = buttonsContainerStacking;

  return twMerge(
    mobileStacking === 'vertical' && mobileAlign === 'center'
      ? `mobile:max-tablet:text-center`
      : mobileAlign === 'right'
      ? `mobile:max-tablet:text-end`
      : `mobile:max-tablet:text-start`,
    mobileStacking === 'horizontal' && mobileAlign === 'center'
      ? `mobile:max-tablet:justify-center`
      : mobileAlign === 'right'
      ? `mobile:max-tablet:justify-end`
      : `mobile:max-tablet:justify-start`,

    tabletStacking === 'vertical' && tabletAlign === 'center'
      ? `tablet:max-desktop:text-center`
      : tabletAlign === 'right'
      ? `tablet:max-desktop:text-end`
      : `tablet:max-desktop:text-start`,
    tabletStacking === 'horizontal' && tabletAlign === 'center'
      ? `tablet:max-desktop:justify-center`
      : tabletAlign === 'right'
      ? `tablet:max-desktop:justify-end`
      : `tablet:max-desktop:justify-start`,

    desktopStacking === 'vertical' && desktopAlign === 'center'
      ? `desktop:max-large-desktop:text-center`
      : desktopAlign === 'right'
      ? `desktop:max-large-desktop:text-end`
      : `desktop:max-large-desktop:text-start`,
    desktopStacking === 'horizontal' && desktopAlign === 'center'
      ? `desktop:max-large-desktop:justify-center`
      : desktopAlign === 'right'
      ? `desktop:max-large-desktop:justify-end`
      : `desktop:max-large-desktop:justify-start`,

    largeDesktopStacking === 'vertical' && largeDesktopAlign === 'center'
      ? `large-desktop:text-center`
      : largeDesktopAlign === 'right'
      ? `large-desktop:text-end`
      : `large-desktop:text-start`,
    largeDesktopStacking === 'horizontal' && largeDesktopAlign === 'center'
      ? `large-desktop:justify-center`
      : largeDesktopAlign === 'right'
      ? `large-desktop:justify-end`
      : `large-desktop:justify-start`
  );
}

export function getFavoriteButtonIcons({
  isFavorite,
  favoritesAction,
}: {
  isFavorite: boolean;
  favoritesAction: any;
}) {
  const favoriteActionDefaultAsset =
    favoritesAction?.customConfiguration?.mobile_flavour_1_default_asset || '';
  const favoriteActionActiveAsset =
    favoritesAction?.customConfiguration?.mobile_flavour_1_active_asset || '';

  const selectedActionIcons = {
    mobile: favoriteActionActiveAsset,
    tablet: favoriteActionActiveAsset,
    desktop: favoriteActionActiveAsset,
    'large-desktop': favoriteActionActiveAsset,
  };
  const unselectedActionIcons = {
    mobile: favoriteActionDefaultAsset,
    tablet: favoriteActionDefaultAsset,
    desktop: favoriteActionDefaultAsset,
    'large-desktop': favoriteActionDefaultAsset,
  };

  return {
    mobile: isFavorite
      ? selectedActionIcons.mobile
      : unselectedActionIcons.mobile,
    tablet: isFavorite
      ? selectedActionIcons.tablet
      : unselectedActionIcons.tablet,
    desktop: isFavorite
      ? selectedActionIcons.desktop
      : unselectedActionIcons.desktop,
    'large-desktop': isFavorite
      ? selectedActionIcons['large-desktop']
      : unselectedActionIcons['large-desktop'],
  };
}

export function getButtonsContainerButtonFillModeClasses(
  assets: any,
  buttonNumber: ButtonNumber
) {
  const buttonDisplayMode: any = getCellBreakpointValues({
    config: assets,
    key: `button${buttonNumber}_display_mode`,
  });

  return twMerge(
    buttonDisplayMode.mobile === 'fill' && 'mobile:max-tablet:flex-grow',
    buttonDisplayMode.tablet === 'fill' && 'tablet:max-desktop:flex-grow',
    buttonDisplayMode.desktop === 'fill' &&
      'desktop:max-large-desktop:flex-grow',
    buttonDisplayMode['large-desktop'] === 'fill' && 'large-desktop:flex-grow'
  );
}

export function getButtonsContainerButtonContentAlignmentClasses({
  assets,
  buttonNumber,
  assetAlignment,
}: {
  assets: any;
  buttonNumber: ButtonNumber;
  assetAlignment: AssetAlignmentResponsive;
}) {
  const buttonContentAlignment: any = getCellBreakpointValues({
    config: assets,
    key: `button${buttonNumber}_content_alignment`,
  });

  const buttonDisplayMode: any = getCellBreakpointValues({
    config: assets,
    key: `button${buttonNumber}_display_mode`,
  });

  const isReversedAlignmentClassMobile =
    assetAlignment.mobile === 'left' &&
    buttonContentAlignment.mobile !== 'center';
  const isReversedAlignmentClassTablet =
    assetAlignment.tablet === 'left' &&
    buttonContentAlignment.tablet !== 'center';
  const isReversedAlignmentClassDesktop =
    assetAlignment.desktop === 'left' &&
    buttonContentAlignment.desktop !== 'center';
  const isReversedAlignmentClassLargeDesktop =
    assetAlignment['large-desktop'] === 'left' &&
    buttonContentAlignment['large-desktop'] !== 'center';

  if (isReversedAlignmentClassMobile) {
    buttonContentAlignment.mobile =
      buttonContentAlignment.mobile === 'left' ? 'right' : 'left';
  }
  if (isReversedAlignmentClassTablet) {
    buttonContentAlignment.tablet =
      buttonContentAlignment.tablet === 'left' ? 'right' : 'left';
  }
  if (isReversedAlignmentClassDesktop) {
    buttonContentAlignment.desktop =
      buttonContentAlignment.desktop === 'left' ? 'right' : 'left';
  }
  if (isReversedAlignmentClassLargeDesktop) {
    buttonContentAlignment['large-desktop'] =
      buttonContentAlignment['large-desktop'] === 'left' ? 'right' : 'left';
  }

  const mobile = getJustifyContentClassName(buttonContentAlignment.mobile);
  const tablet = getJustifyContentClassName(buttonContentAlignment.tablet);
  const desktop = getJustifyContentClassName(buttonContentAlignment.desktop);
  const largeDesktop = getJustifyContentClassName(
    buttonContentAlignment['large-desktop']
  );

  return twMerge(
    buttonDisplayMode.mobile === 'fixed' && `mobile:max-tablet:${mobile}`,
    buttonDisplayMode.mobile === 'fill' && `mobile:max-tablet:justify-center`,

    buttonDisplayMode.tablet === 'fixed' && `tablet:max-desktop:${tablet}`,
    buttonDisplayMode.tablet === 'fill' && `tablet:max-desktop:justify-center`,

    buttonDisplayMode.desktop === 'fixed' &&
      `desktop:max-large-desktop:${desktop}`,
    buttonDisplayMode.desktop === 'fill' &&
      `desktop:max-large-desktop:justify-center`,

    buttonDisplayMode['large-desktop'] === 'fixed' &&
      `large-desktop:${largeDesktop}`,
    buttonDisplayMode['large-desktop'] === 'fill' &&
      `large-desktop:justify-center`
  );
}
