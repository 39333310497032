import { useEffect, useState } from 'react';
import useIsOnline from '~/hooks/use-is-online.ts';

const NetworkStatusBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const isOnline = useIsOnline();

  useEffect(() => {
    if (!showBanner && !isOnline) {
      setShowBanner(true);
    }

    if (showBanner && isOnline) {
      setTimeout(() => {
        setShowBanner(false);
      }, 3000);
    }
  }, [isOnline, showBanner]);

  if (isOnline && !showBanner) return <></>;

  return (
    <div
      className={`${
        isOnline ? 'bg-green-600' : 'animate-network-banner bg-zinc-600'
      } fixed bottom-0 left-0 right-0 z-50 max-h-7 text-center`}
    >
      <p className="text-white">
        {isOnline ? 'back online' : 'No Internet connection'}
      </p>
    </div>
  );
};

export default NetworkStatusBanner;
