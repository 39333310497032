const manifest = {
  plugin_id: 3776,
  identifier: 'subscription-management',
  manifest_version: '0.0.1-alpha-2',
  dependency_name: '',
  name: 'Subscription Management',
  description: 'Payment Info',
  type: 'ui_component',
  characteristics: {
    disable_default_cell_style: true,
  },
  general: {
    fields: [
      {
        key: 'select_payment_provider',
        label: 'Select Payment Provider',
        type: 'select',
        options: [
          {
            text: 'Cleeng',
            value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_publisher_id',
        label: 'Cleeng Publisher ID',
        type: 'text_input',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_environment',
        label: 'Cleeng Environment',
        type: 'select',
        options: [
          {
            text: 'Sandbox',
            value: 'sandbox',
          },
          {
            text: 'Production',
            value: 'production',
          },
        ],
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_skip_available_downgrades_step',
        label: 'Skip available downgrades step',
        type: 'switch',
        initial_value: false,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_skip_available_free_extension_step',
        label: 'Skip Available Free Extension Step',
        type: 'switch',
        initial_value: false,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_display_grace_period_error',
        label: 'Display grace period error',
        type: 'switch',
        initial_value: false,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
    ],
  },
  data: {
    fields: [
      {
        key: 'authentication_feed_url',
        label: 'Authentication Feed URL',
        type: 'data_source_selector',
      },
    ],
  },
  styles: {
    fields: [
      {
        key: 'cleeng_general_font_color',
        label: 'General Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_success_color',
        label: 'Buttons BG color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(81, 83, 100, 1)',
        tooltip_text: 'Buttons background color (pay buttons)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_primary_color',
        label: 'Primary Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        tooltip_text:
          'Mediastore primary theme color (e.g. background of apply coupon button)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_loader_color',
        label: 'Loader Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        tooltip_text: 'Color of the loader',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_error_color',
        label: 'Errors Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        tooltip_text: 'Color of the error message',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
