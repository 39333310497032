import { type ButtonNumber } from './buttons-container-getters';

// currently applies an "inside" border type effect

const baseClassNames = 'absolute bottom-0 left-0 right-0 top-0 bg-transparent';

/**
 * Power cell border component
 * @param isPowerCellBorderHover - Whether the power cell border hover is active
 */
export function PowerCellBorder({
  isPowerCellBorderHover,
}: {
  isPowerCellBorderHover: boolean;
}): JSX.Element {
  return (
    <div
      className={`${baseClassNames} z-[6] rounded-cell-border-radius border-cell-border-width border-cell-border-color ${
        isPowerCellBorderHover ? 'hover:border-cell-hover-border-color' : ''
      }`}
    ></div>
  );
}

/**
 * Power cell image border component
 * @param isPowerCellImageHover - Whether the power cell image hover is active
 */
export function PowerCellImageBorder({
  isPowerCellImageHover,
}: {
  isPowerCellImageHover?: boolean;
}): JSX.Element {
  return (
    <div
      className={`${baseClassNames} z-[1] rounded-image-border-radius border-image-border-width border-image-border-color ${
        isPowerCellImageHover
          ? 'group-hover:border-[var(--image-hover-border-color)]'
          : ''
      }`}
    ></div>
  );
}

/**
 * Buttons container button border component
 * @param buttonNumber - The button number
 */
export function ButtonsContainerButtonBorder({
  buttonNumber,
}: {
  buttonNumber: ButtonNumber;
}): JSX.Element {
  return (
    <div
      className={`${baseClassNames} z-[1] ${
        buttonNumber === 1
          ? 'border-button1_border_size border-button1_border_color hover:border-button1_hover_border_color'
          : buttonNumber === 2
          ? 'border-button2_border_size border-button2_border_color hover:border-button2_hover_border_color'
          : 'border-button3_border_size border-button3_border_color hover:border-button3_hover_border_color'
      }`}
    ></div>
  );
}

/**
 * Info Cell button element border component
 */
export function InfoCellButtonElementBorder(): JSX.Element {
  return (
    <div
      className={`${baseClassNames} z-[1] rounded-button_element_corner_radius border-button_element_border_thickness border-button_element_border_color`}
    ></div>
  );
}
