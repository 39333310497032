import { twMerge } from 'tailwind-merge';
import type { UIComponent } from '~/services/layout/index.server.ts';
import { type HorizontalListButtonPosition } from './hooks';

export function HorizontalListButton({
  direction,
  show,
  uiComponent,
  isNextFeedLoading,
  onClick,
  position,
}: {
  direction: 'left' | 'right';
  show: boolean;
  uiComponent: UIComponent;
  isNextFeedLoading: boolean;
  onClick: () => void;
  position: HorizontalListButtonPosition;
}) {
  if (!show || !position.isMaxHeightReady) return <></>;

  const horizontalListBtnId = `horizontal-list-${direction}-btn-${uiComponent.id}`;

  return (
    <button
      className={twMerge(
        `tw-horizontal-list-arrow group pointer-events-auto absolute z-10 h-full rounded-horizontal-list-arrow-border-radius bg-horizontal-list-arrow opacity-100 transition duration-500 ease-in-out hover:bg-horizontal-list-arrow-hover`,
        direction === 'left'
          ? 'horizontal-list-arrow-left left-0'
          : 'horizontal-list-arrow-right right-0'
      )}
      style={{
        maxHeight: position.maxHeight,
      }}
      key={horizontalListBtnId}
      data-testid={horizontalListBtnId}
      aria-disabled={!show}
      onClick={onClick}
    >
      <div className={twMerge(isNextFeedLoading && 'opacity-50')}>
        <div className="pointer-events-none h-0 w-0 bg-horizontal-list-left-arrow-asset-hover opacity-0"></div>
        <div className="pointer-events-none h-0 w-0 bg-horizontal-list-right-arrow-asset-hover opacity-0"></div>
        <div
          className={`h-horizontal-list-arrow w-horizontal-list-arrow ${
            direction === 'left'
              ? 'bg-horizontal-list-left-arrow-asset group-hover:bg-horizontal-list-left-arrow-asset-hover'
              : 'bg-horizontal-list-right-arrow-asset group-hover:bg-horizontal-list-right-arrow-asset-hover'
          } bg-contain bg-center bg-no-repeat`}
          key={`horizontal-list-${direction}-btn-icon-${uiComponent.id}`}
        />
      </div>
    </button>
  );
}
