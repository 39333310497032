const manifest = {
  plugin_id: 3677,
  identifier: 'hero_v2',
  manifest_version: '1.0.2',
  dependency_name: '',
  name: 'Hero V2',
  description:
    'You’ll use the `Hero` component in a large variety of screens, listing items of any type.All cells are displayed vertically on top of one another.',
  type: 'ui_component',
  api: {},
  dependency_repository_url: [],
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  screen: false,
  react_native: true,
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  min_zapp_sdk: '0.0.1',
  styles: {
    fields: [
      {
        group: true,
        label: 'Cell Styles',
        folded: false,
        fields: [
          {
            key: 'mobile_cell_plugin_configuration_id',
            label: 'Mobile cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'tablet_cell_plugin_configuration_id',
            label: 'Tablet cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'desktop_cell_plugin_configuration_id',
            label: 'Desktop cell style',
            type: 'cell_style_selector',
          },
          {
            key: 'large_desktop_cell_plugin_configuration_id',
            label: 'Large Desktop cell style',
            type: 'cell_style_selector',
          },
        ],
      },
      {
        group: true,
        label: 'Component configuration',
        folded: false,
        fields: [
          {
            key: 'component_mobile_background_color',
            label: 'Component Mobile Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_tablet_background_color',
            label: 'Component Tablet Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_desktop_background_color',
            label: 'Component Desktop Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_large_desktop_background_color',
            label: 'Component Large Desktop Background Color',
            initial_value: 'transparent',
            type: 'color_picker_rgba',
          },
          {
            key: 'component_mobile_margin_top',
            label: 'Component Mobile Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_margin_top',
            label: 'Component Tablet Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_margin_top',
            label: 'Component Desktop Margin Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_margin_top',
            label: 'Component Large Desktop Margin Top',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_margin_bottom',
            label: 'Component Mobile Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_margin_bottom',
            label: 'Component Tablet Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_margin_bottom',
            label: 'Component Desktop Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_margin_bottom',
            label: 'Component Large Desktop Margin Bottom',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_padding_top',
            label: 'Component Mobile Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_top',
            label: 'Component Tablet Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_top',
            label: 'Component Desktop Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_top',
            label: 'Component Large Desktop Padding Top',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_right',
            label: 'Component Mobile Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_right',
            label: 'Component Tablet Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_right',
            label: 'Component Desktop Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_right',
            label: 'Component Large Desktop Padding Right',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_mobile_padding_bottom',
            label: 'Component Mobile Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_bottom',
            label: 'Component Tablet Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_bottom',
            label: 'Component Desktop Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_bottom',
            label: 'Component Large Desktop Padding Bottom',
            initial_value: null,
            type: 'number_input',
          },

          {
            key: 'component_mobile_padding_left',
            label: 'Component Mobile Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_tablet_padding_left',
            label: 'Component Tablet Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_desktop_padding_left',
            label: 'Component Desktop Padding Left',
            initial_value: null,
            type: 'number_input',
          },
          {
            key: 'component_large_desktop_padding_left',
            label: 'Component Large Desktop Padding Left',
            initial_value: null,
            type: 'number_input',
          },
        ],
      },
    ],
  },
  rules: {
    fields: [
      {
        key: 'component_cells_selectable',
        initial_value: true,
        label: 'Cells selectable',
        type: 'switch',
      },
      {
        key: 'load_feed_from_client',
        label: 'Load feed from the client',
        label_tooltip:
          'If enabled, the feed will be loaded from the browser (can be used to load user specific data such as favorites or continue watching). By default all feeds are loaded from the server.',
        initial_value: false,
        type: 'switch',
      },
    ],
  },
  data: {
    fields: [
      {
        key: 'source',
        label: 'Source',
        type: 'text_input',
      },
    ],
  },
  characteristics: {
    group: false,
    appearance: ['group', 'screen'],
    disable_default_cell_style: true,
  },
} as const;

export default manifest;
