import { useCallback, useMemo } from 'react';
import debounce from 'lodash.debounce';

/**
 * Generates a debounced function
 * useCallback is used to memoize the function
 * useMemo is used to memoize the debounced function
 * @param fn - The function to debounce
 * @param delay - The debounce delay time in milliseconds
 * @returns - The debounced function
 */
export function useDebounce(fn: Function, delay: number) {
  const actionCallback = useCallback(fn, []);

  return useMemo(() => {
    return debounce(actionCallback as any, delay, {
      leading: true,
    });
  }, [actionCallback]);
}
