import { useLoaderData } from '@remix-run/react';

type LoaderData = {
  screen: any;
  webviewUrl: string | undefined;
};

const WebView = (): JSX.Element => {
  const { screen, webviewUrl } = useLoaderData<LoaderData>();

  if (!webviewUrl) return <></>;

  return (
    <div className={`screen-${screen.id} h-screen`}>
      <iframe
        title={screen?.general?.webview_accessibility_title || 'iframe'}
        sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation"
        src={webviewUrl}
        className="h-full w-full"
      />
    </div>
  );
};

export default WebView;
