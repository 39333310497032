import React, { Suspense, lazy } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import { type AuthData } from '~/services/plugins/payment-info/components/index.tsx';
import { setCSSVariables } from './styles/utils.ts';
import './styles/styles.css';

const PaymentInfo = lazy(() =>
  // @ts-ignore
  import('@cleeng/mediastore-sdk').then((module) => ({
    default: module.PaymentInfo,
  }))
);
const Provider = lazy(() =>
  import('react-redux').then((module) => ({ default: module.Provider }))
);

interface CleengProviderProps {
  config: any;
  authData: AuthData;
}

const getCleengConfig = async () => {
  try {
    // @ts-ignore
    const module = await import('@cleeng/mediastore-sdk');
    const Config = module.Config;
    const Store = module.store;
    return { Config, Store };
  } catch (err) {
    console.error('Failed to load Config', err);
    throw err;
  }
};

export default function CleengProvider({
  config,
  authData,
}: CleengProviderProps) {
  const [loading, setLoading] = React.useState(true);

  const storeRef = React.useRef(null);

  const {
    cleeng_publisher_id,
    cleeng_environment,
    cleeng_show_transactions,
    hidden_payments,
  } = config?.general;

  const initializeCleeng = React.useCallback(async () => {
    const { Config, Store } = await getCleengConfig();

    storeRef.current = Store;

    if (authData && Config && cleeng_publisher_id && cleeng_environment) {
      setCSSVariables({ ...config.styles, cleeng_show_transactions });

      const currentURL = window.location.href;

      Config.setEnvironment(cleeng_environment);
      Config.setPublisher(cleeng_publisher_id);
      Config.setJWT(authData?.access_token);
      Config.setRefreshToken(authData?.refresh_token);
      Config.setMyAccountPayPalUrls({
        successUrl: currentURL,
        cancelUrl: currentURL,
        errorUrl: currentURL,
      });

      if (hidden_payments && hidden_payments.length > 0) {
        const hiddenPayments = String(hidden_payments)
          .split(',')
          .map((payment: string) => Number(payment));

        Config.setHiddenPaymentMethods(hiddenPayments);
      }
    }

    setLoading(false);
  }, [getCleengConfig]);

  React.useEffect(() => {
    initializeCleeng();
  }, []);

  return (
    <ClientOnly fallback={<></>}>
      {() => {
        if (loading) {
          return null;
        }

        return (
          <Suspense fallback={<></>}>
            <Provider
              // @ts-ignore
              store={storeRef.current}
            >
              <Suspense fallback={<></>}>
                <div className="payment-info">
                  <PaymentInfo />
                </div>
              </Suspense>
            </Provider>
          </Suspense>
        );
      }}
    </ClientOnly>
  );
}
