import React from 'react';
import { twMerge } from 'tailwind-merge';
import get from 'lodash.get';

import { getPayloadImgSrc } from '~/services/layout/layout.ts';
import Label from '../label.tsx';
import { MaybeLink } from '../maybe-link.tsx';
import { cellTextLabels } from '~/services/layout/text-labels.ts';
import Runtime from '../cell-components/runtime.tsx';
import { ActionIcon } from '~/components/cell-components/action-icon.tsx';

import type { CellProps } from './index.tsx';

function ResponsiveHeroCell({
  cellId,
  entry,
  cellConfig,
  isClient,
  cellStyles,
  linkTo,
  linkToTrailer,
  analyticsData,
}: CellProps) {
  const overlaySrc = get(
    cellStyles[cellId],
    'configuration.assets.image_overlay'
  );

  const imageSrc: string | undefined = getPayloadImgSrc(
    cellConfig?.configuration?.assets,
    entry
  );

  const { text_label1, text_label2, text_label3 } = cellTextLabels(
    entry,
    cellConfig
  );

  const metadataKeys = cellConfig?.configuration?.styles?.metadata_keys || '';

  const metadataItems = metadataKeys?.split(',')?.map((key: string) => {
    return get(entry, key);
  });

  const playActionLinkTo: string | undefined = linkTo;

  const styles = cellConfig?.configuration?.styles;

  return (
    <MaybeLink
      to={playActionLinkTo}
      analyticsdata={analyticsData}
      enabled={!styles?.play_action_switch && !styles?.trailer_action_switch}
    >
      <div
        className={twMerge(
          `cell-${cellId} flex w-cell shrink-0 flex-col-reverse rounded-cell-radius bg-cell lg:flex-row`,
          isClient && 'animate-cell-scale'
        )}
      >
        <div className="flex flex-[2] flex-col justify-center gap-2 pb-cell-b pl-cell-l pr-cell-r pt-cell-t">
          <Label
            className={`cell-${cellId}-text_label_1`}
            text={text_label1}
            component={styles?.text_label_1_typography}
          />
          <Label
            className={`cell-${cellId}-text_label_2`}
            text={text_label2}
            component={styles?.text_label_2_typography}
          />
          <Label
            className={`cell-${cellId}-text_label_3`}
            text={text_label3}
            component={styles?.text_label_3_typography}
          />
          <div className="flex gap-4 text-gray-200">
            {styles?.metadata_switch &&
              React.Children.toArray(
                metadataItems?.map((item: any) => (
                  <Label
                    className={`cell-${cellId}-metadata`}
                    text={item}
                    key={item}
                  />
                ))
              )}
            <Runtime
              hero
              entry={entry}
              enabled={cellConfig?.configuration.styles?.runtime_switch}
            />
          </div>
          <div className="flex items-center justify-start gap-2">
            {styles?.play_action_switch && playActionLinkTo && (
              <MaybeLink to={playActionLinkTo} analyticsdata={analyticsData}>
                <Label
                  className={`cell-${cellId}-play_action`}
                  iconSrc={styles?.play_action_icon}
                  text={styles?.play_action_text_label}
                />
              </MaybeLink>
            )}
            {styles?.trailer_action_switch && linkToTrailer && (
              <MaybeLink to={linkToTrailer} analyticsdata={analyticsData}>
                <Label
                  className={`cell-${cellId}-trailer_action`}
                  iconSrc={styles?.trailer_action_icon}
                  text={styles?.trailer_action_text_label}
                />
              </MaybeLink>
            )}
            <ActionIcon
              cellConfig={cellConfig}
              entry={entry}
              isPositionedAbsolute={false}
            />
          </div>
        </div>
        <div className="relative flex-[3]">
          {overlaySrc && (
            <img
              alt={entry.title}
              className="absolute hidden aspect-cell-image w-full lg:block"
              src={overlaySrc}
            />
          )}
          <img
            src={imageSrc}
            alt={`${entry.title}`}
            srcSet={renderScrSet(imageSrc)}
            className="aspect-cell-image w-full rounded-image-radius bg-image-placeholder"
          />
        </div>
      </div>
    </MaybeLink>
  );
}

function renderScrSet(imageSrc?: string) {
  if (!imageSrc) return undefined;

  try {
    const imageSrcSetURL = new URL(imageSrc);

    if (imageSrcSetURL.searchParams.has('w')) {
      return [300, 600, 1200]
        .map((s) => {
          imageSrcSetURL.searchParams.delete('h');
          imageSrcSetURL.searchParams.set('w', s.toString());
          return `${imageSrcSetURL.href} ${s}w`;
        })
        .join(', ');
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
}

export default ResponsiveHeroCell;
