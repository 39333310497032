import type { UIComponent } from '../layout/index.server.ts';

export function getPluginIdentifier(
  cellStyles: any,
  cellId: string
): string | undefined {
  return cellStyles[cellId]?.plugin_identifier;
}

/**
 * Determines if the feed is loaded on the client side
 * @param uiComponent UIComponent
 * @returns true if the feed should be loaded on the client side
 */
export function getIsClientLoaded(uiComponent: UIComponent) {
  return (
    uiComponent?.data?.source?.startsWith('pipesv2://') ||
    uiComponent?.rules?.load_feed_from_client
  );
}

export const actionToComponentType: Record<
  string,
  'continue-watching' | 'favorites'
> = {
  'pipesv2://continue-watching': 'continue-watching',
  'pipesv2://local_storage_favourites_action_web': 'favorites',
  'pipesv2://local_storage_favourites_action': 'favorites',
} as const;
