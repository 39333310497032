import React, { useCallback, useEffect } from 'react';
import { useFetcher } from '@remix-run/react';
import { PAYMENT_PROVIDER_CLEENG } from '~/routes/payment';
import type { UIComponent } from '~/services/layout/index.server.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';
import { isValidUrl } from '~/utils/validations.ts';
import CleengProvider from './Providers/Cleeng';
import manifest from '../config/manifest.ts';

type Props = {
  uiComponent: UIComponent;
};

export interface AuthData {
  access_token: string;
  refresh_token: string;
}

export default function PaymentInfo({ uiComponent }: Props): JSX.Element {
  const fetcher = useFetcher();
  const config = getPluginConfig<typeof manifest>(manifest, uiComponent);

  useEffect(() => {
    const authURL = config?.data?.authentication_feed_url;

    if (!isValidUrl(authURL?.source)) {
      return;
    }

    fetcher.submit(authURL, {
      action: '/api/payment-auth',
      method: 'post',
    });
  }, []);

  const { select_payment_provider } = config?.general;

  const renderStorefrontProvider = useCallback(() => {
    if (select_payment_provider === PAYMENT_PROVIDER_CLEENG) {
      return (
        <CleengProvider config={config} authData={fetcher.data as AuthData} />
      );
    }
  }, [config, select_payment_provider, fetcher.data]);

  if (!fetcher.data) {
    return <></>;
  }

  return <>{renderStorefrontProvider()}</>;
}
