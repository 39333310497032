import { useEffect } from 'react';
import { useFetcher, useLoaderData } from '@remix-run/react';

import { getComputedUrl } from '~/services/pipes/utils.ts';
import type { loader as rootLoader } from '~/routes/_index.tsx';

import type { UIComponent } from '~/services/layout/index.server.ts';
import { getClientFeedUrl } from '~/services/request/index.ts';
import { isValidUrl } from '~/utils/validations.ts';

export type Props = {
  Component: (props: any) => JSX.Element;
  uiComponent: UIComponent;
};

function ClientUiComponent({ Component, uiComponent }: Props): JSX.Element {
  const { serverLoadedFeeds } = useLoaderData<typeof rootLoader>();
  const fetcher = useFetcher();

  const uiComponentDataSource: string | undefined = uiComponent?.data?.source;

  useEffect(() => {
    const feedUrl = getComputedUrl(
      uiComponent,
      undefined,
      null,
      serverLoadedFeeds?.[0]?.feed?.entry?.[0]
    );

    if (!isValidUrl(feedUrl)) return;

    if (!uiComponentDataSource) return;

    if (fetcher.state === 'idle' && !fetcher.data) {
      const apiEndPoint: string = getClientFeedUrl(feedUrl!);

      fetcher.load(apiEndPoint);
    }
  }, [fetcher.data]);

  return (
    <Component
      isClient={true}
      isLoading={fetcher.state !== 'idle' && !fetcher.data}
      uiComponent={uiComponent}
      feed={fetcher.data}
    />
  );
}

export default ClientUiComponent;
