import { twMerge } from 'tailwind-merge';

import { getPayloadImgSrc } from '~/services/layout/layout.ts';
import Label from '../label.tsx';
import { MaybeLink } from '../maybe-link.tsx';
import { cellTextLabels } from '~/services/layout/text-labels.ts';
import { ActionIcon } from '~/components/cell-components/action-icon.tsx';
import Runtime from '../cell-components/runtime.tsx';
import PayloadImage from '../cell-components/payload-image.tsx';
import ProgressBar from '../cell-components/progress-bar.tsx';

import type { CellProps } from './index.tsx';

function Cell2({
  cellId,
  entry,
  cellConfig,
  isClient,
  progress,
  linkTo,
  useImageLoading,
  isCircularScrolling,
  selectable,
  cellRef,
  analyticsData,
}: CellProps) {
  const { text_label1, text_label2, text_label3, text_label4 } = cellTextLabels(
    entry,
    cellConfig
  );

  const styles = cellConfig?.configuration?.styles;

  const imageSrc: string | undefined = getPayloadImgSrc(
    cellConfig?.configuration?.assets,
    entry
  );

  return (
    <MaybeLink
      to={linkTo}
      enabled={selectable}
      linkariainfo={entry?.title}
      analyticsdata={analyticsData}
    >
      <div
        ref={cellRef}
        className={twMerge(
          `cell-${cellId} flex shrink-0 rounded-cell-radius`,
          isCircularScrolling ? 'w-[100%]' : 'w-cell',
          isClient && 'animate-cell-scale'
        )}
      >
        <div className="relative bg-transparent">
          <Runtime
            entry={entry}
            enabled={cellConfig?.configuration?.styles?.runtime_switch}
          />
          <ActionIcon cellConfig={cellConfig} entry={entry} />
          <PayloadImage
            imageSrc={imageSrc}
            entryTitle={entry?.title}
            classNames="flex-shrink-1 aspect-cell-image w-image-width"
            useImageLoading={useImageLoading}
          />

          <ProgressBar
            progress={progress}
            enabled={cellConfig?.configuration?.assets?.progress_bar_switch}
          />
        </div>
        <div>
          <Label
            className={`cell-${cellId}-text_label_1`}
            text={text_label1}
            component={styles?.text_label_1_typography}
          />
          <Label
            className={`cell-${cellId}-text_label_2`}
            text={text_label2}
            component={styles?.text_label_2_typography}
          />
          <Label
            className={`cell-${cellId}-text_label_3`}
            text={text_label3}
            component={styles?.text_label_3_typography}
          />
          <Label
            className={`cell-${cellId}-text_label_4`}
            text={text_label4}
            component={styles?.text_label_4_typography}
          />
        </div>
      </div>
    </MaybeLink>
  );
}

export default Cell2;
