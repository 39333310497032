import { useEffect } from 'react';
import { useFetcher, useLocation } from '@remix-run/react';

export function useScreenHooks(screenId: string, hasScreenHooks: boolean) {
  const fetcher = useFetcher();
  const location = useLocation();

  useEffect(() => {
    if (!hasScreenHooks) return;

    const requestUrl = location.search
      ? `${location.pathname}${location.search}`
      : location.pathname;

    fetcher.load(
      `/api/maybe-redirect?screenId=${encodeURIComponent(
        screenId
      )}&requestUrl=${encodeURIComponent(requestUrl)}`
    );
  }, [location.pathname, location.search]);

  const isLoading = !fetcher.data && hasScreenHooks;

  return isLoading;
}
