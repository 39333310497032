import { useEffect, useState } from 'react';

export function useInView({
  ref,
  threshold = 0.1,
}: {
  ref: any;
  threshold?: number;
}): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const isSupported: boolean = 'IntersectionObserver' in window;

    if (!ref.current || !isSupported) return;

    const observer = new IntersectionObserver(
      (entries: any) => {
        if (isIntersecting) return;
        setIsIntersecting(!!entries[0]?.isIntersecting);
      },
      {
        threshold,
      }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
