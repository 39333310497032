const manifest = {
  plugin_id: 3710,
  identifier: 'payment_info',
  manifest_version: '0.0.1-alpha-13',
  dependency_name: '',
  name: 'Payment Info',
  description: 'Payment Info',
  type: 'ui_component',
  characteristics: {
    disable_default_cell_style: true,
  },
  general: {
    fields: [
      {
        key: 'select_payment_provider',
        label: 'Select Payment Provider',
        type: 'select',
        options: [
          {
            text: 'Cleeng',
            value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_publisher_id',
        label: 'Cleeng Publisher ID',
        type: 'text_input',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_environment',
        label: 'Cleeng Environment',
        type: 'select',
        options: [
          {
            text: 'Sandbox',
            value: 'sandbox',
          },
          {
            text: 'Production',
            value: 'production',
          },
        ],
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_show_transactions',
        label: 'Show transactions history',
        type: 'switch',
        initial_value: true,
      },
      {
        key: 'hidden_payments',
        label: 'Hidden payments',
        tooltip_text:
          'Comma separated list of hidden payments Ids. Ids could be provided by Cleeng in case needed',
        type: 'text_input',
        initial_value: '',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
    ],
  },
  data: {
    fields: [
      {
        key: 'authentication_feed_url',
        label: 'Authentication Feed URL',
        type: 'data_source_selector',
      },
    ],
  },
  styles: {
    fields: [
      {
        key: 'payment_info_general_font_color',
        label: 'General Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_general_font_size',
      //   label: 'General Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_general_font_family',
      //   label: 'General Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_general_add_button_bg_color',
        label: 'Add Button Background color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(93,185,143, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'payment_info_wrapper_padding_top',
        label: 'Padding Top',
        type: 'number_input',
        initial_value: 20,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'payment_info_wrapper_padding_right',
        label: 'Padding Right',
        type: 'number_input',
        initial_value: 20,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'payment_info_wrapper_padding_bottom',
        label: 'Padding Bottom',
        type: 'number_input',
        initial_value: 20,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'payment_info_wrapper_padding_left',
        label: 'Padding Left',
        type: 'number_input',
        initial_value: 20,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'payment_info_title_font_color',
        label: 'Title Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_title_font_size',
      //   label: 'Title Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_title_font_family',
      //   label: 'Title Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_card_title_font_color',
        label: 'Card Title Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_card_title_font_size',
      //   label: 'Card Title Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_card_title_font_family',
      //   label: 'Card Title Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_card_subtitle_font_color',
        label: 'Card Subtitle Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_card_subtitle_font_size',
      //   label: 'Card Subtitle Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_card_subtitle_font_family',
      //   label: 'Card Subtitle Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_details_title_font_color',
        label: 'Details Title Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_details_title_font_size',
      //   label: 'Details Title Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_details_title_font_family',
      //   label: 'Details Title Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },

      {
        key: 'payment_info_details_subtitle_font_color',
        label: 'Details Subtitle Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_details_subtitle_font_size',
      //   label: 'Details Subtitle Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_details_subtitle_font_family',
      //   label: 'Details Subtitle Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_method_font_color',
        label: 'Payment Method Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_method_font_size',
      //   label: 'Payment Method Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_method_font_family',
      //   label: 'Payment Method Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_back_btn_font_color',
        label: 'Payment Back Button Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      // {
      //   key: 'payment_info_back_btn_font_size',
      //   label: 'Payment Back Button Font Size',
      //   type: 'number_input',
      //   initial_value: 16,
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      // {
      //   key: 'payment_info_back_btn_font_family',
      //   label: 'Payment Back Button Font Family',
      //   type: 'web_font_selector',
      //   rules: 'conditional',
      //   conditional_fields: [
      //     {
      //       key: 'general/select_payment_provider',
      //       condition_value: 'Cleeng',
      //     },
      //   ],
      // },
      {
        key: 'payment_info_back_btn_bg_color',
        label: 'Payment Back Button Background Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
