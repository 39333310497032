const manifest = {
  plugin_id: 1182,
  identifier: 'group-info-qb',
  manifest_version: '1.0.0',
  name: 'Group Info',
  description: 'Group Info',
  type: 'ui_component',
  styles: {
    fields: [
      {
        key: 'component_margin_top',
        label: 'Component Margin Top',
        initial_value: 0,
        type: 'number_input',
      },
      {
        key: 'component_margin_bottom',
        label: 'Component Margin Bottom',
        initial_value: 0,
        type: 'number_input',
      },
      {
        key: 'component_padding_top',
        label: 'Component Padding Top',
        initial_value: 0,
        type: 'number_input',
      },
      {
        key: 'component_padding_bottom',
        label: 'Component Padding Bottom',
        initial_value: 0,
        type: 'number_input',
      },
      {
        key: 'component_padding_right',
        label: 'Component Padding Right',
        initial_value: 0,
        type: 'number_input',
      },
      {
        key: 'component_padding_left',
        label: 'Component Padding Left',
        initial_value: 0,
        type: 'number_input',
      },
      {
        key: 'color_picker_rgba',
        label: 'Component Background Color',
        initial_value: 'rgba(0, 0, 0, 0)',
        type: 'color_picker_rgba',
      },
    ],
  },
  rules: {
    fields: [
      {
        key: 'hide_component_if_data_is_empty',
        label: 'Hide component if data is empty',
        initial_value: true,
        type: 'switch',
      },
      {
        key: 'component_cells_selectable',
        initial_value: true,
        label: 'Cells selectable',
        type: 'switch',
      },
    ],
  },
  localizations: {
    fields: [
      {
        type: 'text_input',
        label: 'Title override',
        key: 'title_override',
        initial_value: '',
      },
    ],
  },
  characteristics: {
    group: false,
    appearance: ['group'],
  },
} as const;

export default manifest;
