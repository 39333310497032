import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

function PayloadImage({
  imageSrc,
  entryTitle,
  classNames,
  useImageLoading = false,
}: {
  imageSrc: string | undefined;
  entryTitle: string;
  classNames: string;
  useImageLoading: boolean | undefined;
}) {
  const [imageLoaded, setImageLoaded] = useState<boolean | undefined>(
    typeof useImageLoading === 'boolean' ? !useImageLoading : true
  );

  return (
    <>
      {imageSrc && (
        <>
          {!imageLoaded && (
            <div
              className={twMerge(
                'animate-pulse rounded-sm bg-skeleton_background_color',
                classNames
              )}
            />
          )}
          <img
            alt={entryTitle}
            className={twMerge(
              classNames,
              'hidden bg-image-placeholder',
              imageLoaded && 'block'
            )}
            src={imageSrc}
            onLoad={() => setImageLoaded(true)}
            data-payload-image
          />
        </>
      )}
    </>
  );
}

export default PayloadImage;
