import type { FeedEntry, UIComponent } from '~/services/layout/index.server.ts';
import {
  getCellsScrolled,
  getClientWidth,
  getGapWidth,
  getToggleButtonsState,
  getTotalCells,
  getTotalScrollWidth,
  getVisibleCells,
  getWidth,
} from './getters.ts';
import { useEffect } from 'react';
import { useReadyState } from '~/hooks/use-ready-state.ts';

//#region Horizontal List Resize Hook
export function useHorizontalListResize({
  entries,
  ref,
  cellRef,
  nextFeedUrl,
  isCircularStarted,
  setToggleButtons,
}: {
  entries: FeedEntry[] | undefined;
  ref: any;
  cellRef: any;
  nextFeedUrl: string | undefined;
  isCircularStarted: boolean;
  setToggleButtons: any;
}) {
  const readyState = useReadyState();

  const handleResize = (): void => {
    const cellWidth: number = getWidth({
      ref: cellRef,
    });

    if (cellWidth === 0) return;

    const enabled: boolean = isEnabledOnResize({
      entries,
      ref,
      cellRef,
      nextFeedUrl,
      isCircularStarted,
    });

    const { left, right } = getToggleButtonsState({
      ref,
      isCircularStarted,
      nextFeedUrl,
    });

    setToggleButtons({
      enabled,
      left,
      right,
      now: new Date().getTime(),
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [readyState]);
}

function isEnabledOnResize({
  entries,
  ref,
  cellRef,
  nextFeedUrl,
  isCircularStarted,
}: {
  entries: FeedEntry[] | undefined;
  ref: any;
  cellRef: any;
  nextFeedUrl: string | undefined;
  isCircularStarted: boolean;
}): boolean {
  const gap = getGapWidth({
    ref,
  });

  const containerWidth: number = getClientWidth({ ref });

  const cellWidth: number = getWidth({
    ref: cellRef,
  });

  return (
    !!(
      containerWidth &&
      containerWidth < (cellWidth + gap) * entries!.length - gap
    ) ||
    isCircularStarted ||
    !!nextFeedUrl
  );
}
//#endregion

// #region Horizontal List Dispach Resize Event Hook
/**
 * Dispach resize event when the right button is enabled
 * @param ref - The horizontal list container ref
 * @param isCircularStarted - The circular state
 * @param nextFeedUrl - The next feed url
 */
export function useDispachResizeEvent({
  ref,
  isCircularStarted,
  nextFeedUrl,
}: {
  ref: any;
  isCircularStarted: boolean;
  nextFeedUrl: string | undefined;
}) {
  const { right } = getToggleButtonsState({
    ref,
    isCircularStarted,
    nextFeedUrl,
  });

  useEffect(() => {
    if (!right) return;

    setTimeout(() => {
      const event = new Event('resize');
      window.dispatchEvent(event);
    }, 0);
  }, [right]);
}
// #endregion

//#region Horizontal List Scroll Hook
export function useHorizontalListScroll({
  ref,
  cellRef,
  isCircularStarted,
  isScrolling,
  setIsScrolling,
  setToggleButtons,
  setCellIndex,
  nextFeedUrl,
}: {
  ref: any;
  cellRef: any;
  isCircularStarted: boolean;
  isScrolling: boolean;
  setIsScrolling: any;
  setToggleButtons: any;
  setCellIndex: any;
  nextFeedUrl: string | undefined;
}): void {
  let isScrollingTimeout: NodeJS.Timeout;

  const handleScroll = () => {
    const { right, left } = getToggleButtonsState({
      ref,
      isCircularStarted,
      nextFeedUrl,
    });

    const currentLeftsideCellIndex = getCellsScrolled({ ref, cellRef });

    setCellIndex(currentLeftsideCellIndex);

    setToggleButtons((prev: any) => {
      return {
        ...prev,
        left,
        right,
      };
    });

    if (!isScrolling) setIsScrolling(true);

    clearTimeout(isScrollingTimeout);

    isScrollingTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 200);
  };
  useEffect(() => {
    handleScroll();

    ref.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!ref.current) return;
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);
}
//#endregion

//#region Horizontal List Circular Hook
export function useHorizontalListCircular({
  uiComponent,
  ref,
  cellRef,
  toggleButtons,
  isClient,
  screenPadding,
  nextFeedUrl,
  setFeedEntries,
  feedEntries,
  cellIndex,
  isCircularStarted,
  setIsCircularStarted,
  isScrolling,
}: {
  uiComponent: UIComponent | undefined;
  ref: any;
  cellRef: any;
  toggleButtons: {
    enabled: boolean;
    now: number;
  };
  isClient: boolean;
  screenPadding: number;
  nextFeedUrl: string | undefined;
  setFeedEntries: any;
  feedEntries: FeedEntry[] | undefined;
  cellIndex: number;
  isCircularStarted: boolean;
  setIsCircularStarted: any;
  isScrolling: boolean;
}): void {
  const isCircularEnabled: boolean = getIsCircularEnabled({
    uiComponent,
    toggleButtons,
  });

  useEffect(() => {
    if (!isCircularEnabled) return;

    let totalCells: number = getTotalCells({
      entries: feedEntries,
      isClient,
    });

    const visibleCells: number = getVisibleCells({
      ref,
      cellRef,
      screenPadding,
    });

    if (visibleCells === 0 || totalCells === 0) return;

    const isScrolled: boolean = cellIndex + 1 >= visibleCells;

    if (!nextFeedUrl && isScrolled && !isCircularStarted && !isScrolling) {
      setFeedEntries((prevFeedEntries: FeedEntry[] | []) => {
        if (!feedEntries?.length) return prevFeedEntries;

        if (feedEntries.length !== prevFeedEntries.length) {
          return prevFeedEntries;
        }

        const newFeedEntries: FeedEntry[] = [
          ...prevFeedEntries,
          ...feedEntries,
        ];

        return newFeedEntries;
      });

      setIsCircularStarted(true);

      return;
    }

    if (!isCircularStarted || isScrolling) return;

    // Circular scroll to the left
    const totalScrollWidth: number = getTotalScrollWidth({ ref });

    const gap: number = getGapWidth({
      ref,
    });

    const isLeft: boolean = cellIndex === 0;

    const isRight: boolean = cellIndex + visibleCells >= totalCells - 1;

    const containerWidth: number = getClientWidth({ ref });

    const left: number = isLeft
      ? totalScrollWidth / 2 - gap / 2
      : isRight
      ? totalScrollWidth / 2 - containerWidth + gap / 2
      : NaN;

    if (isNaN(left)) return;

    ref.current.scrollTo({
      left,
      behavior: 'instant',
    });
  }, [
    cellIndex,
    toggleButtons?.now,
    isScrolling,
    nextFeedUrl,
    isCircularStarted,
  ]);
}

function getIsCircularEnabled({
  uiComponent,
  toggleButtons,
}: {
  uiComponent: UIComponent | undefined;
  toggleButtons: {
    enabled: boolean;
  };
}): boolean {
  if (!toggleButtons.enabled) return false;

  return uiComponent?.styles?.horizontal_list_circular_scrolling || false;
}

//#endregion
