import { createField } from '~/utils/manifest-utils';

export function getDateTransformManifestFields(textLabel: string) {
  const labelPrefix = 'Date Transform';

  return [
    createField({
      key: `${textLabel}_date_transform_switch`,
      type: 'switch',
      label: `${labelPrefix} Enable`,
      initial_value: false,
      conditional_fields: [
        { key: `styles/${textLabel}_switch`, condition_value: true },
      ],
    }),
    createField({
      key: `${textLabel}_date_transform`,
      type: 'select',
      label: labelPrefix,
      initial_value: 'DD/MM/YYYY',
      options: [
        { text: 'D/M/YY', value: 'D/M/YY' },
        { text: 'YYYY', value: 'YYYY' },
        { text: 'MMM D', value: 'MMM D' },
        { text: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
        { text: 'MMMM D', value: 'MMMM D' },
        { text: 'YYYY h:mm', value: 'YYYY h:mm' },
        { text: 'Other', value: 'other' },
      ],
      conditional_fields: [
        {
          key: `styles/${textLabel}_date_transform_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${textLabel}_custom_date_transform`,
      type: 'text_input',
      label: `Custom ${labelPrefix} Format`,
      initial_value: null,
      conditional_fields: [
        {
          key: `styles/${textLabel}_date_transform`,
          condition_value: 'other',
        },
      ],
    }),
  ];
}
