import { useResponsiveValue } from '~/hooks/use-responsive-breakpoint';
import { getIsAllCellsInView } from './getters';
import { useLoaderData } from '@remix-run/react';
import type { loader } from '~/routes/_index.tsx';

export function useHorizontalListResponsiveValues({
  rules,
  styles,
  entries,
}: {
  rules: any;
  styles: any;
  entries: any;
}) {
  const autoscrollSwitch = useResponsiveValue({
    mobile: rules?.autoscroll_switch_mobile,
    tablet: rules?.autoscroll_switch_tablet,
    desktop: rules?.autoscroll_switch_desktop,
    largeDesktop: rules?.autoscroll_switch_large_desktop,
  });

  const autoscrollInterval = useResponsiveValue({
    mobile: rules?.autoscroll_interval_mobile,
    tablet: rules?.autoscroll_interval_tablet,
    desktop: rules?.autoscroll_interval_desktop,
    largeDesktop: rules?.autoscroll_interval_large_desktop,
  });

  const loopSwitch = useResponsiveValue({
    mobile: rules?.loop_switch_mobile,
    tablet: rules?.loop_switch_tablet,
    desktop: rules?.loop_switch_desktop,
    largeDesktop: rules?.loop_switch_large_desktop,
  });

  const isAllCellsInView = getIsAllCellsInView({
    styles,
    entriesCount: entries?.length || 0,
  });

  const showButtons = useResponsiveValue({
    mobile: styles?.arrows_mobile_switch && !isAllCellsInView.mobile,
    tablet: styles?.arrows_tablet_switch && !isAllCellsInView.tablet,
    desktop: styles?.arrows_desktop_switch && !isAllCellsInView.desktop,
    largeDesktop:
      styles?.arrows_large_desktop_switch && !isAllCellsInView.largeDesktop,
  });

  const componentGutter = useResponsiveValue({
    mobile: styles?.component_mobile_gutter_horizontal,
    tablet: styles?.component_tablet_gutter_horizontal,
    desktop: styles?.component_desktop_gutter_horizontal,
    largeDesktop: styles?.component_large_desktop_gutter_horizontal,
  });

  const { themeV2Config } = useLoaderData<typeof loader>();

  const componentThemeGutter = useResponsiveValue({
    mobile: themeV2Config.component_mobile_gutter_horizontal,
    tablet: themeV2Config.component_tablet_gutter_horizontal,
    desktop: themeV2Config.component_desktop_gutter_horizontal,
    largeDesktop: themeV2Config.component_large_desktop_gutter_horizontal,
  });

  const gutter = componentGutter ?? componentThemeGutter;

  const componentPaddingLeft = useResponsiveValue({
    mobile: styles?.component_mobile_padding_left,
    tablet: styles?.component_tablet_padding_left,
    desktop: styles?.component_desktop_padding_left,
    largeDesktop: styles?.component_large_desktop_padding_left,
  });

  const componentThemePaddingLeft = useResponsiveValue({
    mobile: themeV2Config.component_mobile_padding_left,
    tablet: themeV2Config.component_tablet_padding_left,
    desktop: themeV2Config.component_desktop_padding_left,
    largeDesktop: themeV2Config.component_large_desktop_padding_left,
  });

  const paddingLeft = componentPaddingLeft ?? componentThemePaddingLeft;

  const nextCellOffset = useResponsiveValue({
    mobile: styles?.next_cell_mobile_offset,
    tablet: styles?.next_cell_tablet_offset,
    desktop: styles?.next_cell_desktop_offset,
    largeDesktop: styles?.next_cell_large_desktop_offset,
  });

  const cellScalingSwitch = useResponsiveValue({
    mobile: styles?.mobile_cell_scaling_switch,
    tablet: styles?.tablet_cell_scaling_switch,
    desktop: styles?.desktop_cell_scaling_switch,
    largeDesktop: styles?.large_desktop_cell_scaling_switch,
  });

  const indicatorsAlignment = useResponsiveValue(
    {
      mobile: styles?.mobile_indicators_alignment,
      tablet: styles?.tablet_indicators_alignment,
      desktop: styles?.desktop_indicators_alignment,
      largeDesktop: styles?.large_desktop_indicators_alignment,
    },
    false
  );

  return {
    autoscrollSwitch,
    autoscrollInterval,
    loopSwitch,
    showButtons,
    componentGutter,
    componentPaddingLeft,
    gutter,
    paddingLeft,
    nextCellOffset,
    cellScalingSwitch,
    indicatorsAlignment,
  };
}
