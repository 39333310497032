import type {
  UIComponent,
  UIComponentType,
} from '~/services/layout/index.server.ts';
import { isValidUrl } from './validations.ts';
import logger from '~/services/logger';

/**
 * Get the left and right button icons based on component_type
 * if one of the icons is invalid both will be set and return as undefined,
 * then lucide icons will be set by calling createIcons.
 * @example
 * const { leftBtnIcon, rightBtnIcon } = getUiComponentIcons(uiComponent);
 * @param uiComponent UIComponent
 * @returns leftBtnIcon and rightBtnIcon or undefined if invalid
 */
export function getComponentIcons(uiComponent: UIComponent): {
  leftBtnIcon: string | undefined;
  rightBtnIcon: string | undefined;
} {
  try {
    const uiComponentType: UIComponentType = uiComponent.component_type;

    let leftBtnIcon: string | undefined = undefined;
    let rightBtnIcon: string | undefined = undefined;

    if (uiComponentType === 'horizontal_list_qb') {
      leftBtnIcon = uiComponent?.styles?.horizontal_list_left_button_icon;
      rightBtnIcon = uiComponent?.styles?.horizontal_list_right_button_icon;
    }

    if (uiComponentType === 'hero-qb') {
      leftBtnIcon = uiComponent?.styles?.hero_carousel_left_button_icon;
      rightBtnIcon = uiComponent?.styles?.hero_carousel_right_button_icon;
    }

    const isInvalidIcons: boolean =
      !isValidUrl(leftBtnIcon) || !isValidUrl(rightBtnIcon);

    return {
      leftBtnIcon: isInvalidIcons ? undefined : leftBtnIcon,
      rightBtnIcon: isInvalidIcons ? undefined : rightBtnIcon,
    };
  } catch (error: any) {
    logger.info(`Error in getComponentIcons: ${error.message}`);
    return {
      leftBtnIcon: undefined,
      rightBtnIcon: undefined,
    };
  }
}
