import { useCallback } from 'react';
import type { Dispatch, SetStateAction } from 'react';

function getIndicatorsAlignmentClassName(alignment: string) {
  switch (alignment) {
    case 'left':
      return 'self-start';
    case 'right':
      return 'self-end';
    case 'center':
    default:
      return 'self-center';
  }
}

function getIndicatorsPositionClassName(position: string) {
  switch (position) {
    case 'top':
      return 'absolute bottom-0';
    case 'below':
    default:
      return '';
  }
}

function HorizontalListIndicators({
  cellContainerRef,
  entries,
  alignment,
  position,
  arrowsSwitch,
  arrowLeftAsset,
  arrowRightAsset,
  circularCondition,
  scrollLeft,
  scrollRight,
  currentIndicatorsIndex,
  setCurrentIndicatorsIndex,
  show,
}: {
  cellContainerRef: React.RefObject<HTMLDivElement>;
  entries: Array<any>;
  alignment: string;
  position: string;
  arrowsSwitch?: boolean;
  arrowLeftAsset?: string;
  arrowRightAsset?: string;
  circularCondition: boolean;
  scrollLeft: () => void;
  scrollRight: () => void;
  currentIndicatorsIndex: number;
  setCurrentIndicatorsIndex: Dispatch<SetStateAction<number>>;
  show: boolean;
}) {
  const onIndicatorPress = useCallback((i: number) => {
    cellContainerRef.current?.children[i].scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'center',
    });

    setCurrentIndicatorsIndex(i);
  }, []);

  if (!show) return <></>;

  return (
    <div
      className={`horizontal-list-indicators-margin flex w-fit items-center rounded-horizontal-list-indicators border-horizontal-list-indicators-width border-horizontal-list-indicators-color bg-horizontal-list-indicators pb-horizontal-list-indicators-b pl-horizontal-list-indicators-l pr-horizontal-list-indicators-r pt-horizontal-list-indicators-t ${getIndicatorsAlignmentClassName(
        alignment
      )} ${getIndicatorsPositionClassName(position)}`}
    >
      {arrowsSwitch && arrowLeftAsset && (
        <button
          onClick={scrollLeft}
          disabled={currentIndicatorsIndex === 0 && !circularCondition}
          data-testid="horizontal-list-indicators-left-arrow"
          className={`mb-horizontal-list-indicator-arrow-left-b ml-horizontal-list-indicator-arrow-left-l mr-horizontal-list-indicator-arrow-left-r mt-horizontal-list-indicator-arrow-left-t h-horizontal-list-indicator-arrow w-horizontal-list-indicator-arrow bg-horizontal-list-indicator-arrow-left-asset bg-contain bg-center bg-no-repeat ${
            currentIndicatorsIndex === 0
              ? 'bg-horizontal-list-indicator-arrow-left-asset-inactive'
              : 'hover:bg-horizontal-list-indicator-arrow-left-asset-hover'
          }`}
        />
      )}
      <div className="flex gap-horizontal-list-indicators">
        {entries?.map((entry, i) => {
          return (
            <button
              key={entry?._key}
              onClick={() => onIndicatorPress(i)}
              className={`${
                currentIndicatorsIndex === i
                  ? 'border-horizontal-list-indicator-color-selected bg-horizontal-list-indicator-selected hover:border-horizontal-list-indicator-color-selected-hover'
                  : ''
              } h-horizontal-list-indicators w-horizontal-list-indicators rounded-horizontal-list-indicator border-horizontal-list-indicator-width border-horizontal-list-indicator-color bg-horizontal-list-indicator hover:border-horizontal-list-indicator-color-hover hover:bg-horizontal-list-indicator-hover
          `}
            />
          );
        })}
      </div>
      {arrowsSwitch && arrowRightAsset && (
        <button
          onClick={scrollRight}
          disabled={
            currentIndicatorsIndex === entries?.length - 1 && !circularCondition
          }
          data-testid="horizontal-list-indicators-right-arrow"
          className={`mb-horizontal-list-indicator-arrow-right-b ml-horizontal-list-indicator-arrow-right-l mr-horizontal-list-indicator-arrow-right-r mt-horizontal-list-indicator-arrow-right-t h-horizontal-list-indicator-arrow w-horizontal-list-indicator-arrow bg-horizontal-list-indicator-arrow-right-asset bg-contain bg-center bg-no-repeat ${
            currentIndicatorsIndex === entries?.length - 1
              ? 'bg-horizontal-list-indicator-arrow-right-asset-inactive'
              : 'hover:bg-horizontal-list-indicator-arrow-right-asset-hover'
          }`}
        />
      )}
    </div>
  );
}

export default HorizontalListIndicators;
