import { useEffect, useMemo, useState } from 'react';
import { epgTheme } from './epg-theme.ts';
import { useEpg, type Program, type Channel } from 'planby-mit';
import { useFetcher, useLoaderData } from '@remix-run/react';
import { isValidUrl } from '~/utils/validations.ts';
import logger from '~/services/logger';
import { Feed } from '~/services/layout/index.server.ts';
import { getEpgImage } from '~/services/layout/layout.ts';

export function useEpgResources({
  uiComponent,
  feed,
  itemHeight,
  channelSidebarWidth,
}: {
  uiComponent: any;
  feed: Feed;
  itemHeight: number;
  channelSidebarWidth: number;
}): {
  getEpgProps: any;
  getLayoutProps: any;
  isLoading: boolean;
} {
  const programsFeedUrl: string = uiComponent?.data?.programs_source?.source;

  const mappingProperty: string | undefined = getMappingProperty(uiComponent);

  const { channels, epgProgramsUrls, channelsUuIds } =
    getChannelsAndProgramsUrls({
      channelsFeed: feed,
      programsFeedUrl,
      mappingProperty,
    });

  const [epg, setEpg] = useState<Program[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMock } = useLoaderData<any>();
  const mockDate = '2024-03-17';
  const [currentTime, setCurrentTime] = useState<string>(
    isMock ? mockDate : new Date().toISOString()
  );

  const epgData: Program[] = useMemo(() => epg, [epg]);

  const fetcher = useFetcher();

  useEffect(() => {
    if (!isValidUrl(programsFeedUrl)) {
      logger.info('Invalid programs feed url');
      return;
    }

    if (fetcher.data) return;

    const programsTitleKey: string =
      uiComponent?.styles?.epg_item_label_1_data_key || 'title';

    fetcher.submit(
      {
        epgProgramsUrls: JSON.stringify(epgProgramsUrls),
        channelsUuIds: JSON.stringify(channelsUuIds),
        programsTitleKey,
      },
      {
        method: 'post',
        action: `/api/epg-programs`,
      }
    );

    setIsLoading(true);
  }, []);

  const { getEpgProps, getLayoutProps } = useEpg({
    channels,
    epg: epgData,
    dayWidth: 7200,
    sidebarWidth: channelSidebarWidth,
    itemHeight,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    isBaseTimeFormat: true,
    startDate: currentTime,
    theme: epgTheme(uiComponent.styles),
  });

  useEffect(() => {
    if (!fetcher.data) return;

    const epgInternalFeedResponse: any = fetcher.data;

    if (epgInternalFeedResponse?.error) {
      setIsLoading(false);
      logger.info('Internal EPG api 500 error');
      return;
    }

    setCurrentTime(
      isMock
        ? new Date(mockDate).toISOString()
        : findEarliestDateInPrograms(epgInternalFeedResponse)
    );

    setEpg(epgInternalFeedResponse);

    setIsLoading(false);
  }, [fetcher.data]);

  return { getEpgProps, getLayoutProps, isLoading };
}

/**
 * Gets the EPG mapping property from the uiComponent.
 * @param uiComponent the uiComponent.
 * @returns the EPG mapping property or undefined.
 */
export function getMappingProperty(uiComponent: any): string | undefined {
  try {
    const programsFeedUrl: string = uiComponent.data.programs_source.source;

    const keyForProgramsUrl: RegExpMatchArray | null =
      programsFeedUrl.match(/\{\{([^}]+)\}\}/);

    if (!keyForProgramsUrl)
      throw new Error('No key found in {{}} for programs url');

    if (typeof keyForProgramsUrl[1] !== 'string')
      throw new Error('No key for programs url found');

    const mapping = uiComponent?.data?.programs_source?.mapping;

    if (!mapping) throw new Error('No mapping found');

    const mappingProperty: string | undefined =
      mapping?.[keyForProgramsUrl[1]]?.property;

    if (mappingProperty === '') throw new Error('No mapping property found');

    return mappingProperty;
  } catch (error: any) {
    logger.info(`getMappingProperty: ${error.message}`);
    return undefined;
  }
}

/**
 * Finds the earliest date minus 30min in the programs array.
 * @param programs the programs array.
 * @returns the earliest date in the programs array as a string value in ISO format.
 */
export function findEarliestDateInPrograms(programs: Program[]): string {
  try {
    if (programs.length === 0) throw new Error('No programs');

    let earliestDate = programs[0].since;

    programs.forEach((program: Program) => {
      if (program.since < earliestDate) {
        earliestDate = program.since;
      }
    });

    return `${earliestDate}`;
  } catch (error) {
    return new Date().toISOString().split('T')[0];
  }
}

type NormalizedChannels = {
  logo: string;
  uuid: string;
}[];

function getChannelsAndProgramsUrls({
  channelsFeed,
  programsFeedUrl,
  mappingProperty,
}: {
  channelsFeed: any;
  programsFeedUrl: string;
  mappingProperty: string | undefined;
}): {
  channels: any;
  channelsUuIds: string[];
  epgProgramsUrls: string[];
} {
  try {
    if (!channelsFeed) throw new Error('No channels data found');

    if (channelsFeed?.entry?.length === 0) {
      throw new Error('No channels entry data found in the EPG feed');
    }

    const channels: NormalizedChannels = normalizeChannelsFeed(
      channelsFeed.entry!
    );

    const channelsUuIds: string[] = [];
    const epgProgramsUrls: string[] = [];

    channelsFeed.entry.forEach((channelEntry: any) => {
      const channelId: string = channelEntry.id;
      channelsUuIds.push(channelId);

      const keyForProgramsUrl: RegExpMatchArray | null =
        programsFeedUrl.match(/\{\{([^}]+)\}\}/);

      if (!keyForProgramsUrl)
        throw new Error('No key found in {{}} for programs url');

      const programsUrlParam: string =
        mappingProperty || keyForProgramsUrl[1] || channelId;

      const singleChannelProgramsUrl: string = programsFeedUrl.replace(
        `{{${programsUrlParam}}}`,
        channelEntry[programsUrlParam]
      );

      epgProgramsUrls.push(singleChannelProgramsUrl);
    });

    return {
      channels,
      epgProgramsUrls,
      channelsUuIds,
    };
  } catch (error: any) {
    logger.info(`Error fetching epg channels: ${error.message}`);
    return {
      channels: [],
      channelsUuIds: [],
      epgProgramsUrls: [],
    };
  }
}

/**
 * Normalizes the channels from the EPG feed.
 * @param channelsEntry the channels entry from the EPG feed.
 * @returns the normalized channels from the EPG feed.
 */
export function normalizeChannelsFeed(channelsEntry: any): NormalizedChannels {
  try {
    const res: any = [];

    channelsEntry.forEach((channel: Channel) => {
      if (channel?.type?.value !== 'epg-channel') return;

      const logo: string = getEpgImage(channel, 'image_base');
      const uuid: string = channel?.id;

      if (!uuid) return;

      res.push({ logo, uuid });
    });

    return res;
  } catch (error) {
    return [];
  }
}
