import { twMerge } from 'tailwind-merge';

import { getCellAssetSrc, getPayloadImgSrc } from '~/services/layout/layout.ts';
import type { TextComponent } from '~/components/label.tsx';
import Label from '~/components/label.tsx';
import { MaybeLink } from '~/components/maybe-link.tsx';
import { cellTextLabels } from '~/services/layout/text-labels.ts';
import { ActionIcon } from '~/components/cell-components/action-icon.tsx';
import Runtime from '~/components/cell-components/runtime.tsx';
import PayloadImage from '~/components/cell-components/payload-image.tsx';
import ProgressBar from '~/components/cell-components/progress-bar.tsx';

import type { CellProps } from '~/components/cell/index.tsx';
import manifest from '../config/manifest.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';

function Cell1({
  cellId,
  entry,
  cellConfig,
  isClient,
  progress,
  linkTo,
  useImageLoading,
  isCircularScrolling,
  selectable,
  cellRef,
  analyticsData,
}: CellProps) {
  const { styles, assets } = getPluginConfig<typeof manifest>(
    manifest,
    cellConfig?.configuration
  );

  const staticBadge: string | undefined = getCellAssetSrc(
    cellConfig,
    'static_badge'
  );

  const { text_label1, text_label2, text_label3 } = cellTextLabels(
    entry,
    cellConfig
  );

  const imageSrc: string | undefined = getPayloadImgSrc(assets, entry);

  return (
    <MaybeLink
      enabled={selectable}
      to={linkTo}
      linkariainfo={entry?.title}
      analyticsdata={analyticsData}
    >
      <div
        ref={cellRef}
        className={twMerge(
          `cell-${cellId} shrink-0 rounded-cell-radius`,
          isCircularScrolling ? 'w-[100%]' : 'w-cell',
          isClient && 'animate-cell-scale'
        )}
      >
        <div className="relative bg-transparent">
          <Runtime entry={entry} enabled={styles?.runtime_switch} />
          <ActionIcon cellConfig={cellConfig} entry={entry} />
          {staticBadge && (
            <div
              className={twMerge(
                !imageSrc &&
                  'relative flex aspect-cell-image h-fit w-full items-center justify-center rounded-image-radius'
              )}
            >
              <img
                alt="Play Icon"
                src={staticBadge}
                className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform"
              />
            </div>
          )}

          <PayloadImage
            imageSrc={imageSrc}
            entryTitle={entry?.title}
            classNames="aspect-cell-image w-full rounded-image-radius"
            useImageLoading={useImageLoading}
          />

          <ProgressBar
            progress={progress}
            enabled={assets?.progress_bar_switch}
          />
        </div>
        <div>
          <Label
            className={`cell-${cellId}-text_label_1`}
            component={styles?.text_label_1_typography as TextComponent}
            text={text_label1}
          />
          <Label
            className={`cell-${cellId}-text_label_2`}
            component={styles?.text_label_2_typography as TextComponent}
            text={text_label2}
          />
          <Label
            className={`cell-${cellId}-text_label_3`}
            component={styles?.text_label_3_typography as TextComponent}
            text={text_label3}
          />
        </div>
      </div>
    </MaybeLink>
  );
}

export default Cell1;
