import { number } from 'zod';
import logger from '~/services/logger';

/**
 * A progress bar component
 * @param enabled Whether the progress bar should be enabled
 * @param progress The progress of the progress bar (0-1)
 * @returns A JSX progress bar component for continue watching
 */
function ProgressBar({
  progress,
  enabled,
}: {
  progress?: number;
  enabled: boolean;
}) {
  try {
    if (!enabled || !progress) return <></>;

    const progressWidth = number().gte(0).lte(1).parse(progress) * 100;

    return (
      <div className="absolute bottom-0 h-progress-bar w-full rounded-progress-bar-progress bg-progress-bar-total progress-bar-margins">
        <div
          className="h-progress-bar rounded-progress-bar-total bg-progress-bar-progress"
          style={{ width: `${progressWidth}%` }}
        />
      </div>
    );
  } catch (error: any) {
    logger.info(`ProgressBar: ${error?.message}`);
    return <></>;
  }
}

export default ProgressBar;
