import logger from '~/services/logger';
import {
  responsiveBreakpoints,
  seoSelectedBreakpoint,
} from '~/services/layout/layout';
import {
  getCellVisibillityClasses,
  type ResponsiveSources,
} from './power-cell-getters';
import { PowerCellImageBorder } from './power-cell-border';

type BaseParams = {
  imageSources: ResponsiveSources;
  classNames: string;
  altText: string;
};

type ExclusiveParams =
  | {
      powerCellImage?: { isPowerCellImageHover?: boolean };
      isHoverImage?: never;
    }
  | { powerCellImage?: never; isHoverImage?: boolean };

type ResponsiveImageParams = BaseParams & ExclusiveParams;

/**
 * Renders the image content for each breakpoint
 * @param imageSources - Image sources for each breakpoint
 * @param classNames - Class names for the image
 * @param altText - Alt text for the image
 * @param powerCellImage - Power cell image object with hover state boolean
 * @param isHoverImage - Boolean value to check if the image is hover image or not so the hidden class can be added accordingly
 * @returns - JSX.Element with responsive image
 */
export function ResponsiveImage({
  imageSources,
  classNames,
  altText,
  powerCellImage,
  isHoverImage,
}: ResponsiveImageParams): JSX.Element {
  try {
    const defaultImageSource = imageSources[seoSelectedBreakpoint];

    const responsiveImageContainerClasses = isHoverImage
      ? getCellVisibillityClasses(imageSources)
      : powerCellImage
      ? 'overflow-hidden rounded-image-border-radius'
      : '';

    return (
      <div className={responsiveImageContainerClasses}>
        <picture data-payload-image>
          {typeof imageSources.mobile === 'string' && (
            <source
              srcSet={imageSources.mobile}
              media={`(max-width: ${responsiveBreakpoints.tablet - 1}px)`}
            />
          )}
          {typeof imageSources.tablet === 'string' && (
            <source
              srcSet={imageSources.tablet}
              media={`(max-width: ${
                responsiveBreakpoints.desktop - 1
              }px) and (min-width: ${responsiveBreakpoints.tablet}px)`}
            />
          )}
          {typeof imageSources.desktop === 'string' && (
            <source
              srcSet={imageSources.desktop}
              media={`(max-width: ${
                responsiveBreakpoints['large-desktop'] - 1
              }px) and (min-width: ${responsiveBreakpoints.desktop}px)`}
            />
          )}
          {typeof imageSources['large-desktop'] === 'string' && (
            <source
              srcSet={imageSources['large-desktop']}
              media={`(min-width: ${responsiveBreakpoints['large-desktop']}px)`}
            />
          )}
          {typeof defaultImageSource === 'string' && (
            <img
              src={defaultImageSource}
              alt={altText}
              className={getCellVisibillityClasses(imageSources, classNames)}
            />
          )}
          {powerCellImage && (
            <PowerCellImageBorder
              isPowerCellImageHover={powerCellImage?.isPowerCellImageHover}
            />
          )}
        </picture>
      </div>
    );
  } catch (error: any) {
    logger.info(`ResponsiveImage: ${error.message}`);
    return <></>;
  }
}
