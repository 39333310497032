import logger from '~/services/logger';
import { type CellTextLabelsV2 } from './power-cell-getters';
import { type ResponsiveBreakpointNames } from '~/services/layout/layout';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function applyDateTransformOnTextLabels({
  textLabels,
  styles,
  timeZoneHeader,
}: {
  textLabels: CellTextLabelsV2;
  styles: any;
  timeZoneHeader: string | null;
}): CellTextLabelsV2 {
  try {
    const transformedTextLabels = Object.entries(textLabels).reduce(
      (responsiveTextLabels, [breakpoint, value]) => {
        const transformedValue = Object.entries(value).reduce(
          (acc, [key, value]) => {
            const dateTransformSwitch =
              styles[breakpoint][`${key}_date_transform_switch`];

            if (!value || !dateTransformSwitch || typeof value === 'boolean') {
              acc[key] = value;
              return acc;
            }

            let format: string = styles[breakpoint][`${key}_date_transform`];

            if (format === 'other') {
              const customDateTransform =
                styles[breakpoint][`${key}_custom_date_transform`];

              if (customDateTransform) format = customDateTransform;
            }

            let transformedValue: string;

            if (timeZoneHeader) {
              transformedValue = dayjs(value).tz(timeZoneHeader).format(format);
            } else {
              transformedValue = dayjs(value).format(format);
            }

            if (transformedValue === 'Invalid Date') {
              acc[key] = value;
              return acc;
            }

            acc[key] = transformedValue;

            return acc;
          },
          {} as CellTextLabelsV2[ResponsiveBreakpointNames]
        );

        responsiveTextLabels[breakpoint as ResponsiveBreakpointNames] =
          transformedValue;

        return responsiveTextLabels;
      },
      {} as CellTextLabelsV2
    );

    return transformedTextLabels;
  } catch (error: any) {
    logger.info(`applyDateTransformOnTextLabels: ${error.message}`);
    return textLabels;
  }
}
