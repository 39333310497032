import logger from '~/services/logger';
import React from 'react';

import Hero from '../services/plugins/hero/components/index.tsx';
import GroupInfo from '../services/plugins/group-info/components/index.tsx';
import Grid from './grid.tsx';
import List from './list.tsx';
import UserAccount from './user-account.tsx';
import HorizontalList from './horizontal-list/index.tsx';
import HorizontalListV2 from '~/services/plugins/horizontal-list/components/index.tsx';

import type { Feed, UIComponent } from '~/services/layout/index.server.ts';
import { getLimit } from '~/services/layout/layout.ts';
import EmptyGroup from './empty-group.tsx';
import GridV2 from '~/services/plugins/grid_v2/components/index.tsx';
import { GroupInfoV2 } from '~/services/plugins/group_info_v2/components/index.tsx';
import EmptyGroupV2 from '~/services/plugins/empty_group_v2/components/index.tsx';
import ListV2 from '~/services/plugins/list_v2/components/index.tsx';

type Props = {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
};

const isEmptyGroup = (el: any) =>
  ['empty_group_v2', 'empty_group_component'].includes(el.component_type);

const isGroupInfo = (el: any) =>
  ['group-info-qb', 'group_info_v2'].includes(el.component_type);

const MaybeGroupInfo = ({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: {
  uiComponent?: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
}) => {
  return (
    <>
      {uiComponent?.component_type === 'group-info-qb' && (
        <GroupInfo
          isLoading={isLoading}
          uiComponent={uiComponent}
          feed={feed}
          isClient={isClient}
        />
      )}

      {uiComponent?.component_type === 'group_info_v2' && (
        <GroupInfoV2
          uiComponent={uiComponent}
          feed={feed}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

const MaybeEmptyGroup = ({
  uiComponent,
  feed,
}: {
  uiComponent?: UIComponent;
  feed?: Feed;
}) => {
  if (feed?.entry && feed?.entry?.length > 0) return <></>;
  return (
    <>
      {uiComponent?.component_type === 'empty_group_component' && (
        <EmptyGroup uiComponent={uiComponent} />
      )}
      {uiComponent?.component_type === 'empty_group_v2' && (
        <EmptyGroupV2 uiComponent={uiComponent} />
      )}
    </>
  );
};

const components = {
  horizontal_list_qb: HorizontalList,
  horizontal_list_v2: HorizontalListV2,
  'grid-qb': Grid,
  grid_v2: GridV2,
  'list-qb': List,
  'hero-qb': Hero,
  'quick-brick-user-account-ui-component': UserAccount,
  list_v2: ListV2,
};

const MaybeGroupComponents = ({
  uiComponents,
  feed,
  repeatPatternType,
  repeatNumOfComponents,
  isClient,
  isLoading,
}: {
  feed?: Feed;
  uiComponents: UIComponent[];
  repeatPatternType: 'no' | 'all' | 'last';
  repeatNumOfComponents?: number;
  isClient?: boolean;
  isLoading?: boolean;
}) => {
  const feedLength = feed?.entry?.length || 0;

  const componentsLimits = uiComponents.map((c) =>
    getLimit(c.rules?.item_limit)
  );

  const limits: (number | null | undefined)[] = [];

  let itemsLeft = feedLength;
  let index = 0;
  let componentList = [];
  while (itemsLeft > 0) {
    componentList.push(uiComponents[index % uiComponents.length]);
    const itemsUsed = componentsLimits[index % uiComponents.length];
    limits.push(itemsUsed);
    if (!itemsUsed) break;
    itemsLeft -= itemsUsed;
    // Break if repeatPatternType is 'no' or 'last' and we are at the end of the list
    if (
      ['no', 'last'].includes(repeatPatternType) &&
      index + 1 === uiComponents.length
    )
      break;
    index++;
  }

  if (repeatPatternType === 'last' && repeatNumOfComponents) {
    const lastComponents = uiComponents.slice(-repeatNumOfComponents);
    const lastComponentsLimits = lastComponents.map((c) =>
      getLimit(c.rules?.item_limit)
    );

    while (itemsLeft > 0) {
      componentList.push(lastComponents[index % lastComponents.length]);
      const itemsUsed = lastComponentsLimits[index % lastComponents.length];
      limits.push(itemsUsed);
      if (!itemsUsed) break;
      itemsLeft -= itemsUsed;

      index++;
    }
  }

  if (uiComponents.length === 0) return <></>;

  return (
    <>
      {componentList?.map((c: UIComponent, i) => {
        const feedSubset: Feed = {
          ...feed,
          entry: feed?.entry.slice(0, limits[i] || undefined) || [],
        } as Feed;

        const Comp = components[c.component_type as keyof typeof components];

        return (
          <Comp
            key={i}
            isLoading={isLoading}
            uiComponent={c}
            feed={feedSubset}
            isClient={isClient}
          />
        );
      })}
    </>
  );
};

export default function Group({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: Props): JSX.Element {
  try {
    const isGroupComponent = uiComponent.component_type === 'group-qb';
    const isEmpty = feed?.entry?.length === 0 || !feed?.entry;
    const hideComponentWhenEmpty =
      uiComponent?.rules?.hide_component_if_data_is_empty;
    const hasEmptyGroupComponent =
      uiComponent?.ui_components?.some(isEmptyGroup);

    // Hide group component if it's empty and hideComponentWhenEmpty is true
    if (
      !isLoading &&
      (!isGroupComponent ||
        (isEmpty && hideComponentWhenEmpty && !hasEmptyGroupComponent))
    ) {
      return <></>;
    }

    const groupInfoComponnet = uiComponent?.ui_components?.find(isGroupInfo);

    const emptyGroupComponent = uiComponent?.ui_components?.find(isEmptyGroup);

    const groupComponents = uiComponent?.ui_components?.filter(
      (c) => !isGroupInfo(c) && !isEmptyGroup(c)
    );

    const uiComponentId = uiComponent.id;

    return (
      <div
        className={`group-${uiComponentId} mb-group-b mt-group-t bg-group pb-group-b pl-group-l pr-group-r pt-group-t`}
      >
        {/* Group info component always goes first */}
        <MaybeGroupInfo
          uiComponent={groupInfoComponnet}
          feed={feed}
          isLoading={isLoading}
          isClient={isClient}
        />

        {/* Render empty group component if items is empty */}

        <MaybeEmptyGroup uiComponent={emptyGroupComponent} feed={feed} />

        {/* Render group components */}

        <MaybeGroupComponents
          uiComponents={groupComponents}
          feed={feed}
          repeatPatternType={uiComponent?.rules?.repeat_pattern_type}
          repeatNumOfComponents={uiComponent?.rules?.repeat_num_of_components}
          isClient={isClient}
          isLoading={isLoading}
        />
      </div>
    );
  } catch (error: any) {
    logger.info(`Group: ${error.message}`);
    return <></>;
  }
}
